import React from 'react'

import { Home, Stream, Meeting, Settings, Chat, Participants, Polls } from '../../assets/icons'

import variables from '../../variables.scss'
import './styles.scss'

/**
* @author
* @function SideNav
* @param {String} align -  side nav position 'left'/'right'.
* @param {Boolean} expandView - Whether the current nav-view is expaned or not.
* @param {Boolean} allowExpandView - Whether the nav expansion allowed or not.
* @param {Object} items - nav menu object.
* @param {String} expandType - Tab View or Menu view expansion.
* @param {String} activeColor - Active colour from parent.
* @param {Function} onSelect - Call-back function 
}}
*/

export const SideNav = ({ align, expandView, allowExpandView, items, expandType, onSelectMenu, expandMenu, activeColor, children }) => {

    const navStyle = {
        right: align === 'right' ? 0 : 'unset',
        left: align === 'left' ? 0 : 'unset',
        width: expandView ? 350 : 80,
        borderRight: align === 'left' ? `1px solid ${variables.borderColor}` : 'unset',
        borderLeft: align === 'right' ? `1px solid ${variables.borderColor}` : 'unset',
    }

    const getIcons = (item) => {
        const color = item.isActive ? activeColor : '#bdbdbd';
        switch (item.key) {
            case 'home':
                return <Home fill={color} className="menu-icon" />
            case 'stream':
                return <Stream fill={color} className="menu-icon" />
            case 'meeting':
                return <Meeting fill={color} className="menu-icon" />
            case 'settings':
                return <Settings fill={color} className="menu-icon" />
            case 'chat':
                return <Chat fill={color} className="menu-icon right-nav" />
            case 'participants':
                return <Participants fill={color} className="menu-icon right-nav" />
            case 'polls':
                return <Polls fill={color} className="menu-icon right-nav" />
            default:
                break;
        }
    }

    const renderLiveStatus = () => {
        return (<div className="liveStatus">
            LIVE
        </div>)
    }

    const renderActiveStatus = (item) => {
        return (<div className="activeStatus">
            {item.statusValue ? <span>{item.statusValue}</span> : null}
        </div>)
    }

    return (
        <div className="side-nav" style={navStyle}>
            {allowExpandView && <div className="expand-box"><div className="expand-button" onClick={() => expandMenu(!expandView)}><img alt='arrow' src={expandView ? '/assets/icons/right-chevron.svg' : '/assets/icons/left-chevron.svg'} />
            </div></div>}
            {!expandView &&
                <ul>
                    {items && items.length && items.map((item) => (
                        <li key={item.key} onClick={() => onSelectMenu(item)}>  {getIcons(item)}
                            {item.status === 'LIVE' && renderLiveStatus()}
                            {item.status === 'ACTIVE' && renderActiveStatus(item)}
                        </li>
                    ))}
                </ul>
            }
            <div className="content-box">
                {expandView && expandType === 'Tab' && children}
            </div>
        </div >
    )
}



// export const BASE_URL = 'https://www.yepdesk.com/';
// export const NODE_URL = 'https://node.yepdesk.com/';

export const BASE_URL = 'https://www.625.yepdesk.com/';
export const NODE_URL = 'https://625node.yepdesk.com/';
//export const NODE_URL = 'https://40249527c4ee.ngrok.io/';




// export const BASE_URL = process.env.REACT_BASE_URL;
// export const NODE_URL = process.env.REACT_NODE_URL;



export const ActionType = {
    SHOW_HEADER: "show_header",
    SHOW_FOOTER: "show_footer",
    SET_OFFLINE: 'set_offline',

    SHOW_LEFT_NAV: "show_left_nav",
    SHOW_RIGHT_NAV: "show_right_nav",
    EXPAND_RIGHT_NAV: "expand_right_nav",
    EXPAND_LEFT_NAV: "expand_left_nav",
    HIDE_TABS: "hide_tabs",

    EVENT_DETAILS_GET: "event_details_get",
    SET_MENU_ACTIVE: "set_menu_active",
    SET_TAB_ACTIVE: "set_tab_active",
    SET_CHAT_UNREAD: "set_chat_unread",
    SET_CHAT_READ: "set_chat_read",

    SET_LOGIN_USER: 'set_login_user',
    USER_LOADER: 'user_loader',
    SET_LOGIN_ERROR: 'set_login_error',

    TOAST_SUCCESS: 'toast_success',
    TOAST_ERROR: 'toast_error',
    TOAST_WARNING: 'toast_warning',
    TOAST_INFO: 'toast_info',
    TOAST_PRIVATE_MESSAGE: 'toast_private_message',
    TOAST_CLEAR: 'toast_clear',

    MEET_DETAILS_GET: 'meet_details_get',
    MEET_LOADER: 'meet_loader',
    INIT_MEETING: 'init_meeting',
    JOIN_MEETING: 'join_meeting',
    JOINED_MEETING: 'joined_meeting',
    LEFT_MEETING: 'left_meeting',
    REJOIN_MEETING: 'rejoin_meeting',

    GROUP_CHATS_GET: 'group_chats_get',
    SEND_GROUP_MESSAGE: 'send_group_message',
    GROUP_JOINED_ROOM: 'group_joined_room',
    GROUP_CHAT_LOADER: 'group_chat_loader',
    SET_GROUP_CHAT_ACTIVE: 'set_group_chat_active',
    CLEAR_PREVIOUS_GROUP_MESSAGES: 'clear_previous_group_messages',
    LOAD_PREVIOUS_GROUP_MESSAGES: 'load_previous_group_messages',
    SET_GROUP_CHAT_OFFLINE: 'set_group_chat_offline',
    SET_GROUP_CHAT_RECONNECTING: 'set_group_chat_reconnecting',

    CLEAR_PRIVATE_CHATS_GET: 'clear_private_chats_get',
    PRIVATE_CHATS_GET: 'private_chats_get',
    SEND_PRIVATE_MESSAGE: 'send_private_message',
    PRIVATE_JOINED_ROOM: 'private_joined_room',
    PRIVATE_CHAT_LOADER: 'private_chat_loader',
    LOAD_PREVIOUS_PRIVATE_MESSAGES: 'load_previous_private_message',
    SET_PRIVATE_CHAT_ACTIVE: 'set_private_chat_active',
    SET_PRIVATE_CHAT_OFFLINE: 'set_private_chat_offline',
    SET_PRIVATE_CHAT_RECONNECTING: 'set_private_chat_reconnecting',

    PARTICIPANTS_GET: 'participants_get',
    PARTICIPANTS_JOINED: 'participants_joined',
    PARTICIPANT_LEFT: 'Participant_left',
    SHOW_PRIVATE_CHAT: 'show_private_chat',
    SET_PRIVATE_CHAT_PARTICIPANT: 'set_private_chat_participant',
    SET_PARTICIPANTS_STATE: 'set_participant_state',
    SET_PARTICIPANTS_COUNT: 'set_participants_count',
    PARTICIPANT_READ_MESSAGE: 'participant_read_message',
    SET_UNSEEN_MESSAGE: 'set_unseen_message'
};


export const meetingRoles = {
    MODERATOR: ['Enable or disable video and audio.',
        'Share your screen for presentations.',
        'Record Meetings.',
        'Manage participants etc'
    ],
    PRESENTER: ['Enable or disable video and audio.',
        'Share your screen for presentations.',
    ],
    ATTENDEE: ['You can watch and listen to the session.',
        'Chat with participants (in-meeting chat)',
    ]
};


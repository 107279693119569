import { ActionType } from '../helpers'
import { getGroupChatsFunc } from '../services'
import { error } from '.'

export const getGroupChats = params => dispatch => {
    getGroupChatsFunc(params, dispatch)
        .then(response => {
            dispatch({
                type: ActionType.GROUP_CHATS_GET,
                payload: response.data
            })
        })
        .catch(function (e) {
            dispatch(error({ message: e.message ? e.message : 'Error Occured!' }));
        });
}

export const sendGroupMessage = message => dispatch => {
    dispatch({
        type: ActionType.SEND_GROUP_MESSAGE,
        payload: message
    })

}

export const joinedGroup = data => dispatch => {
    dispatch({
        type: ActionType.GROUP_JOINED_ROOM,
        payload: data
    })

}

export const groupChatLoader = data => dispatch => {
    dispatch({
        type: ActionType.GROUP_CHAT_LOADER,
        payload: data
    })

}

export const setGroupChatActive = payload => dispatch => {
    dispatch({
        type: ActionType.SET_GROUP_CHAT_ACTIVE,
        payload
    })
}

export const clearPreviousGroupMessages = payload => dispatch => {
    dispatch({
        type: ActionType.CLEAR_PREVIOUS_GROUP_MESSAGES,
        payload
    })
}

export const loadPreviousGroupMessages = params => dispatch => {
    getGroupChatsFunc(params, dispatch)
        .then(response => {
            dispatch({
                type: ActionType.LOAD_PREVIOUS_GROUP_MESSAGES,
                payload: response.data
            })
        })
        .catch(function (e) {
            dispatch(error({ message: e.message ? e.message : 'Error Occured!' }));
        });
}


export const setGroupChatOffline = payload => dispatch => {
    dispatch({
        type: ActionType.SET_GROUP_CHAT_OFFLINE,
        payload
    })
}

export const setGroupChatReconnecting = payload => dispatch => {
    dispatch({
        type: ActionType.SET_GROUP_CHAT_RECONNECTING,
        payload
    })
}

import axios from "axios";

import { BASE_URL, getAccessToken, logout } from "./";

export const GET = (
  url,
  params = {},
  header = {},
  data = {},
  withAccessToken = true,
  skipBase = false,
  eventId,
  contentType = "application/json"
) => {
  return new Promise((resolve, reject) => {
    var isPromiseFullfilled = false;
    getAccessToken(withAccessToken, eventId)
      .then(token => {
        const header = { 'content-type': contentType, }
        if (withAccessToken) {
          header.Authorization = 'Bearer ' + token;
        }
        const options = {
          method: "get",
          baseURL: skipBase ? url : BASE_URL,
          headers: header,
          params: params,
          url: !skipBase ? url : ""
        };
        axios(options)
          .then(function (response) {
            if (!isPromiseFullfilled) {
              const { data } = response;
              if (data.message === 'Token has expired') {
                logout(eventId);
              }
              else {
                isPromiseFullfilled = true;
                resolve(response.data);
              }
            }
          })
          .catch(function (error) {
            if (!isPromiseFullfilled) {
              isPromiseFullfilled = true;
              reject({
                status: "error",
                message: "Something went wrong. Please try again"
              });
            }
          });
      })
      .catch(function (error) {
        if (!isPromiseFullfilled) {
          isPromiseFullfilled = true;
          reject({
            status: "error",
            message: "Something went wrong in authentication"
          });
        }
      });
  });
};

export const POST = (
  url,
  params = {},
  header = {},
  data = {},
  withAccessToken = true,
  skipBase = false,
  eventId,
  contentType = "application/json"
) => {
  return new Promise((resolve, reject) => {
    var isPromiseFullfilled = false;
    getAccessToken(withAccessToken, eventId)
      .then(token => {
        const header = { 'content-type': contentType }
        if (withAccessToken) {
          header.Authorization = 'Bearer ' + token;
        }
        const options = {
          method: 'post',
          baseURL: skipBase ? url : BASE_URL,
          headers: header,
          data: data,
          url: !skipBase ? url : ""
        };
        axios(options)
          .then(function (response) {
            if (!isPromiseFullfilled) {
              if (data.message === 'Token has expired') {
                logout(eventId);
              }
              else {
                isPromiseFullfilled = true;
                resolve(response.data);
              }
            }
          })
          .catch(function (error) {
            if (!isPromiseFullfilled) {
              isPromiseFullfilled = true;
              reject({
                status: "error",
                message: "Something went wrong. Please try again"
              });
            }
          });
      })
      .catch(function (error) {
        if (!isPromiseFullfilled) {
          isPromiseFullfilled = true;
          reject({
            status: "error",
            message: "Something went wrong in authentication"
          });
        }
      });
  });
};


export const PUT = (
  url,
  params = {},
  header = {},
  data = {},
  withAccessToken = true,
  skipBase = false,
  eventId,
  contentType = "application/json"
) => {
  return new Promise((resolve, reject) => {
    var isPromiseFullfilled = false;
    getAccessToken(withAccessToken, eventId)
      .then(token => {
        const header = { 'content-type': contentType }
        if (withAccessToken) {
          header.Authorization = 'Bearer ' + token;
        }
        const options = {
          method: "put",
          params: params,
          baseURL: skipBase ? url : BASE_URL,
          headers: header,
          data: data,
          url: !skipBase ? url : ""
        };
        axios(options)
          .then(function (response) {
            if (!isPromiseFullfilled) {
              if (data.message === 'Token has expired') {
                logout(eventId);
              }
              else {
                isPromiseFullfilled = true;
                resolve(response.data);
              }
            }
          })
          .catch(function (error) {
            if (!isPromiseFullfilled) {
              isPromiseFullfilled = true;
              reject({
                status: "error",
                message: "Something went wrong. Please try again"
              });
            }
          });
      })
      .catch(function (error) {
        if (!isPromiseFullfilled) {
          isPromiseFullfilled = true;
          reject({
            status: "error",
            message: "Something went wrong in authentication"
          });
        }
      });
  });
};


export const DELETE = (
  url,
  params = {},
  header = {},
  data = {},
  withAccessToken = true,
  skipBase = false,
  eventId,
  contentType = "application/x-www-form-urlencoded"
) => {
  return new Promise((resolve, reject) => {
    var isPromiseFullfilled = false;
    getAccessToken(withAccessToken, eventId)
      .then(token => {
        const header = {
          'content-type': contentType,
          "Access-Control-Allow-Origin": "*"
        }
        if (withAccessToken) {
          header.Authorization = 'Bearer ' + token;
        }
        const options = {
          method: "delete",
          baseURL: skipBase ? url : BASE_URL,
          headers: header,
          params: params,
          data: data,
          url: !skipBase ? url : ""
        };
        axios(options)
          .then(function (response) {
            if (!isPromiseFullfilled) {
              if (data.message === 'Token has expired') {
                logout(eventId);
              }
              else {
                isPromiseFullfilled = true;
                resolve(response.data);
              }
            }
          })
          .catch(function (error) {
            if (!isPromiseFullfilled) {
              isPromiseFullfilled = true;
              reject({
                status: "error",
                message: "Something went wrong. Please try again"
              });
            }
          });
      })
      .catch(function (error) {
        if (!isPromiseFullfilled) {
          isPromiseFullfilled = true;
          reject({
            status: "error",
            message: "Something went wrong in authentication"
          });
        }
      });
  });
};

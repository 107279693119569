import { ActionType } from '../helpers/constants';

const INTIAL_STATE = {
    duration: 4000,
    position: "top-right",
    title: '',
    data: {}
    
};

export function toast(state = INTIAL_STATE, { type, payload }) {
    switch (type) {
        case ActionType.TOAST_SUCCESS:
            return {
                ...state,
                type: 'success',
                message: payload.message,
                title: payload.title ? payload.title : 'Success !'
            };

        case ActionType.TOAST_ERROR:
            return {
                ...state,
                type: 'error',
                message: payload.message,
                title: payload.title ? payload.title : 'Error !',
            };

        case ActionType.TOAST_WARNING:
            return {
                ...state,
                type: 'warning',
                message: payload.message,
                title: payload.title ? payload.title : 'Warning !',
            };

        case ActionType.TOAST_INFO:
            return {
                ...state,
                type: 'info',
                message: payload.message,
                title: payload.title ? payload.title : 'Info !',
            };

        case ActionType.TOAST_PRIVATE_MESSAGE:
            return {
                ...state,
                type: 'private_message',
                message: payload.message,
                title: payload.title,
                data: {
                    id: payload.userId,
                }
            };

        case ActionType.TOAST_CLEAR:
            return INTIAL_STATE

        default:
            return state
    }
}
import { ActionType } from '../helpers'
import { userLoginFunc } from '../services'
import { showHeader } from '../actions'


export const setLoggedInUser = payload => dispatch => {
    dispatch({
        type: ActionType.SET_LOGIN_USER,
        payload
    })
}

export const userLogin = params => dispatch => {
    dispatch(setLoginError(null));
    dispatch(setLoader(true));
    userLoginFunc(params, dispatch)
        .then(response => {
            dispatch(showHeader(true));
            dispatch({
                type: ActionType.SET_LOGIN_USER,
                payload: response.data
            })
        })
        .catch(function (e) {
            dispatch(setLoader(false));
            dispatch(setLoginError( e.message ? e.message : 'Error Occured!' ));
        });
}

export const setLoader = payload => dispatch => {
    dispatch({
        type: ActionType.USER_LOADER,
        payload
    })
}


export const setLoginError = payload => dispatch => {
    dispatch({
        type: ActionType.SET_LOGIN_ERROR,
        payload
    })
}

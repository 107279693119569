import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import { Home, EventPage, ErrorPage, EventLoginPage, EventMeet, EventDetails, EventStreams } from '../container'

export const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <ErrorPage errorCode="404" />
        </Route>
        <Route path="/" exact component={Home} />
        <Route exact path="/meet/:orgId/:eventURI/:roomId/secure/:password">
          {({ match, location }) => {
            const email = new URLSearchParams(location.search).get('email');
            return (<Redirect to={`/${match.params.eventURI}/meet/${match.params.roomId}?password=${match.params.password}&email=${email}`} />)
          }}
        </Route>
        <Route path="/error/:errorCode" component={ErrorPage} />
        <Route exact path="/:eventURI">
          {({ match }) => <Redirect to={`/${match.params.eventURI}/meet`} />}
        </Route>
        <Route path="/:eventURI" component={EventPage} />
        <Route path="*" > <ErrorPage errorCode="404" /> </Route>
      </Switch>
    </Router>
  )
}

export const EventRoutes = ({ user }) => (
  <>
    <Switch>
      <EventLoginRoute exact path="/:eventURI/login" component={EventLoginPage} user={user} />
      <EventPrivateRoute exact path="/:eventURI/meet" component={EventMeet} user={user} />
      <EventPrivateRoute exact path="/:eventURI/meet/:meetId" component={EventMeet} user={user} />
      <EventPrivateRoute exact path="/:eventURI/stream" component={EventStreams} user={user} />
      {/* <EventPrivateRoute exact path="/:eventURI/details" component={EventDetails} user={user} /> */}
      <Route path="*" > <Redirect to='/error/404' /> </Route>
    </Switch>
  </>
);


const EventPrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    rest.user.isLoggedIn
      ? <Component {...props} />
      : <Redirect to={`/${props.match.params.eventURI}/login`} />
  )} />
)


const EventLoginRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    rest.user.isLoggedIn
      ? <Redirect to={`/${props.match.params.eventURI}/meet`} />
      : <Component {...props} />
  )} />
)
import { ActionType } from '../helpers/constants'

const INTIAL_STATE = {
    loading: false,
    isLoggedIn: false,
    error: null
};

export function user(state = INTIAL_STATE, { type, payload }) {
    switch (type) {
        case ActionType.SET_LOGIN_USER:
            return {
                ...state, ...payload, loading: false, isLoggedIn: true
            };
        case ActionType.USER_LOADER:
            return {
                ...state, loading: payload
            };

        case ActionType.SET_LOGIN_ERROR:
            return {
                ...state, error: payload
            };
        default:
            return state
    }
}
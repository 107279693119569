import React from 'react'
import { useSelector } from 'react-redux'

export const Body = (props) => {

    const root = useSelector(state => state.root);
    
    const bodyStyle = {
        paddingTop: props.hasHeader ? 60 : 0,
        minHeight: props.hasHeader ? 'calc(100vh - 60px)' : '100vh',
        marginLeft: root.showLeftNav ? root.expandLeftNav ? 350 : 80 : 0,
        marginRight: root.showRightNav ? root.expandRightNav ? 350 : 80 : 0,
        transition: 'margin-left .3s ease-in-out, margin-right .3s ease-in-out',
    }

    return (
        <div style={bodyStyle}>
            {props.children}
        </div >
    );
}

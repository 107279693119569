import React, { useState, useEffect, useRef, memo } from 'react'
import Moment from 'react-moment'
import { Spinner } from 'react-bootstrap'
import FadeIn from 'react-fade-in'
import ReactAutolink from 'react-autolink'

import { UserAvatar } from '../../components/UI'
import { Send } from '../../assets/icons'
import { extractNumberFromString, isToday, formatFromToDate, getFirstName } from '../../helpers'


import './styles.scss'

/**
* @author
* @function Chat
* @param {String} chatType -  Chat type PRIVATE / GROUP.
* @param {Function} onSendMessage -  send message action.
* @param {Array} messages - List of messages.
* @param {Object} pagination - Chat pagination object.
* @param {Boolean} loading - Loading state of chat.
* @param {Boolean} showHeader - Has event header.
* @param {Boolean} reconnecting - Socket reconnecting status.
* @param {Function} loadMore - Load more messages action.
* @param {Function} setChatIsActive - Set chat active.
**/

export const Chat = memo(({ chatType, onSendMessage, messages, pagination, loading, showHeader, reconnecting, loadMore, setChatIsActive, loadMessagesAfterReconnection }) => {

    const [message, setMessage] = useState('');
    const [oldScroll, setOldScroll] = useState();
    const [currentScrollPos, setCurrentScrollPos] = useState();
    const [messageAreaHeight, setMessageAreaHeight] = useState({ height: '40px' });
    const [chatAction, setChatAction] = useState();
    const [initChat, setInitChat] = useState(false);
    const [initChatReconnecting, setInitChatReconnecting] = useState(false);
    const [initLoadingChats, setInitLoadingChats] = useState(false);
    const [showGoToBottom, setShowGoToBottom] = useState(false);
    const messagesEndRef = useRef(null);
    const messagesBoxRef = useRef(null);

    let newScroll;

    useEffect(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        setChatAction('BOTTOM');
        setTimeout(() => {
            setInitChat(true);
        }, 800);
    }, []);



    useEffect(() => {
        if (reconnecting) {
            setInitChatReconnecting(true);
            setInitChat(false);
        }

        if (initChatReconnecting) {
            loadMessagesAfterReconnection();
            setTimeout(() => {
                setInitChat(true);
                setInitChatReconnecting(false);
            }, 3000);
        }
    }, [reconnecting]);



    useEffect(() => {
        if (!loading && initChat && !initChatReconnecting) {
            newScroll = messagesBoxRef.current.scrollHeight - messagesBoxRef.current.clientHeight;
            messagesBoxRef.current.scrollTop = currentScrollPos + (newScroll - oldScroll);
            setCurrentScrollPos(messagesBoxRef.current.scrollTop);
            setTimeout(() => {
                setInitLoadingChats(false);
            }, 800);
        }
    }, [loading]);

    const onScroll = () => {
        setChatAction('SCROLL');
        if (!loading && initChat && !initLoadingChats && !initChatReconnecting) {
            setCurrentScrollPos(messagesBoxRef.current.scrollTop);
            if (currentScrollPos <= 30) {
                setOldScroll(messagesBoxRef.current.scrollHeight - messagesBoxRef.current.clientHeight);
                setInitLoadingChats(true);
                messages.length > 1 ? loadMore('messageId', messages[messages.length - 1]._id) : loadMore('pageNo', 0);
            }
        }
        setShowGoToBottom(getScrollActiveState());
    }

    const getScrollActiveState = () => {
        if (messagesBoxRef.current.scrollTop < (messagesBoxRef.current.scrollHeight - messagesBoxRef.current.clientHeight - 250)) {
            return true;
        }
        return false
    }

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        setChatIsActive(true);
        setChatAction('BOTTOM');
    }

    useEffect(() => {
        if (!loading && initChat) {
            if (showGoToBottom && chatAction === 'SCROLL') {
                setChatIsActive(false);
            }
            else {
                scrollToBottom();
            }
        }
    }, [messages]);

    const chatBoxHeight = {
        height: showHeader ? 'calc(100vh - 220px)' : 'calc(100vh - 160px)',
    }

    const onEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (!e.shiftKey) {
                e.preventDefault();
                sendMessage();
            } else
                setMessageAreaHeight({ height: '100px', borderRadius: '.5rem' })
        }
    }

    const sendMessage = () => {
        if (message !== '' && !reconnecting) {
            setChatAction('SEND');
            onSendMessage(message);
            setMessage('');
        }
    }

    const formMessageChange = (event) => {
        setMessage(event.target.value);
    }

    const renderChatTime = ({ createdTime }) => {
        return (
            <>
                {isToday(createdTime) ?
                    <Moment fromNow ago>{createdTime}</Moment>
                    :
                    formatFromToDate(createdTime)
                }
            </>
        )
    }

    const renderMessage = ({ body }) => {
        return (
            <>
                {ReactAutolink.autolink(body, { target: "_blank" })}
            </>
        )
    }

    return (
        <FadeIn transitionDuration="600">
            <div className="mesgs">
                <div className="msg_history" style={chatBoxHeight} ref={messagesBoxRef} onScroll={onScroll}>
                    {loading &&
                        <div style={{ textAlign: 'center' }}>
                            < Spinner animation="grow" variant="grey" />

                        </div>
                    }
                    {messages.slice(0).reverse().map((message, index, messages) => {
                        return (
                            <div key={`message${message._id}${index}`}>
                                {!message.isSender ?
                                    <div className="incoming_msg" >
                                        <div className="incoming_msg_img">
                                            {/* {messages[index + 1].author !== message.author && */}
                                            <UserAvatar
                                                user={{
                                                    name: message.attendee && message.attendee.contact ? message.attendee.contact.displayName ? message.attendee.contact.displayName : message.attendee.contact.firstName : 'Attendee',
                                                    avatar: message.attendee && message.attendee.avatar ? message.attendee.avatar : null,
                                                }}
                                                colorIndex={extractNumberFromString(message.author)}
                                                height={30}
                                            />
                                            {/* } */}
                                        </div>
                                        <div className="received_msg">
                                            <div className="received_withd_msg">
                                                <p>{renderMessage(message)}
                                                    <br />
                                                    <small className="sender-name">
                                                        {message.attendee && message.attendee.contact ? message.attendee.contact.displayName ? getFirstName(message.attendee.contact.displayName) : message.attendee.contact.firstName : 'Attendee'}
                                                    </small>
                                                </p>
                                                <span className="time_date"> {renderChatTime(message)}</span></div>
                                        </div>
                                    </div>
                                    :
                                    <div className="outgoing_msg" >
                                        <div className="sent_msg">
                                            <p>{renderMessage(message)}</p>
                                            <span className="time_date"> {renderChatTime(message)}</span> </div>
                                    </div>
                                }
                            </div>
                        )
                    })}
                    <div ref={messagesEndRef} />
                </div>
                <div className="type_msg">
                    {reconnecting && <div className="reconnecting-msg">
                        <div style={{ margin: '0 auto' }}><Spinner className="mr-2" animation="border" size="sm" />Reconnecting </div>

                    </div>}
                    {!reconnecting && initChat && showGoToBottom && <div className="goto-bottom">
                        <div style={{ margin: '0 auto' }}>
                            <div className="goto-btn" onClick={scrollToBottom}><img alt="arrow" src="/assets/icons/down-arrow.svg" /></div></div>

                    </div>}
                    <div className="input_msg_write">
                        <textarea type="text" className="write_msg" value={message} style={messageAreaHeight}
                            onKeyPress={e => e.key === 'Enter' ? onEnterKeyPress(e) : null}
                            onChange={(e) => formMessageChange(e)} placeholder="Type a message" />
                        <button className="msg_send_btn" type="button" onClick={sendMessage}><Send fill="white" /></button>
                    </div>
                </div>
            </div>
        </FadeIn >
    )
});
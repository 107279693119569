import React from 'react'

export const Settings = ({ fill, className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width="25" height="25" viewBox="0 0 25 25" fill={fill ? fill : '#bdbdbd'}>
            <g id="settings" transform="translate(-26 -291.362)">
                <rect id="Rectangle_2251" data-name="Rectangle 2251" width="25" height="25" transform="translate(26 291.362)" fill="none" />
                <g id="Layer_2" data-name="Layer 2" transform="translate(26 292.053)">
                    <path id="Path_2041" data-name="Path 2041" d="M24.508,10.149a3.259,3.259,0,0,1-2.575-4.407,1.528,1.528,0,0,0-.458-1.721A12.3,12.3,0,0,0,17.808,1.9a1.544,1.544,0,0,0-1.742.482,3.263,3.263,0,0,1-5.133,0A1.544,1.544,0,0,0,9.192,1.9,12.358,12.358,0,0,0,5.775,3.813a1.544,1.544,0,0,0-.483,1.771A3.243,3.243,0,0,1,2.583,10.04a1.54,1.54,0,0,0-1.35,1.239A11.731,11.731,0,0,0,1,13.607a11.88,11.88,0,0,0,.158,1.954A1.54,1.54,0,0,0,2.517,16.85a3.242,3.242,0,0,1,2.65,4.581,1.511,1.511,0,0,0,.425,1.813,12.39,12.39,0,0,0,3.633,2.087,1.67,1.67,0,0,0,.525.091A1.534,1.534,0,0,0,11,24.774a3.227,3.227,0,0,1,2.667-1.4,3.269,3.269,0,0,1,2.617,1.314,1.535,1.535,0,0,0,1.8.507,12.506,12.506,0,0,0,3.333-1.987,1.536,1.536,0,0,0,.45-1.754,3.242,3.242,0,0,1,2.608-4.482,1.54,1.54,0,0,0,1.308-1.264,12.031,12.031,0,0,0,.217-2.1,11.912,11.912,0,0,0-.209-2.223A1.523,1.523,0,0,0,24.508,10.149Zm-6.842,3.459A4.167,4.167,0,1,1,13.5,9.45a4.162,4.162,0,0,1,4.167,4.157Z" transform="translate(-1 -1.804)" />
                </g>
            </g>
        </svg>
    )
}

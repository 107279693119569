import { ActionType, socket } from '../helpers'
import { getPrivateChatsFunc } from '../services'
import { error } from '.'

export const getPrivateChats = params => dispatch => {
    getPrivateChatsFunc(params, dispatch)
        .then(response => {
            dispatch({
                type: ActionType.PRIVATE_CHATS_GET,
                payload: response.data
            })
        })
        .catch(function (e) {
            dispatch(error({ message: e.message ? e.message : 'Error Occured!' }));
        });
}

export const loadPreviousPrivateMessages = params => dispatch => {
    getPrivateChatsFunc(params, dispatch)
        .then(response => {
            dispatch({
                type: ActionType.LOAD_PREVIOUS_PRIVATE_MESSAGES,
                payload: response.data
            })
        })
        .catch(function (e) {
            dispatch(error({ message: e.message ? e.message : 'Error Occured!' }));
        });
}


export const sendPrivateMessage = message => dispatch => {
    dispatch({
        type: ActionType.SEND_PRIVATE_MESSAGE,
        payload: message
    })

}

export const joinedPrivateChat = data => (dispatch, getState) => {

    dispatch({
        type: ActionType.PRIVATE_JOINED_ROOM,
        payload: data
    })

    dispatch({
        type: ActionType.PARTICIPANT_READ_MESSAGE,
        payload: data
    })


    let unSeenMessagePresent = false;
    getState().participants.participants.map(participant => {
        if (participant.unSeen) {
            dispatch({
                type: ActionType.SET_PARTICIPANTS_STATE,
                payload: 'ACTIVE',
            })
            unSeenMessagePresent = true;
            return;
        }
    })

    if (!unSeenMessagePresent) {
        dispatch({
            type: ActionType.SET_PARTICIPANTS_STATE,
            payload: null,
        })
    }

}

export const privateChatLoader = data => dispatch => {
    dispatch({
        type: ActionType.PRIVATE_CHAT_LOADER,
        payload: data
    })

}

export const setPrivateChatActive = payload => dispatch => {
    dispatch({
        type: ActionType.SET_PRIVATE_CHAT_ACTIVE,
        payload
    })
}

export const leavePrivateRoom = payload => (dispatch, getState) => {
    socket.emit('leave-room', getState().privateChats.conversationId, (err) => {
        if (err) {
            dispatch(error({ message: err }));
        }
    });
    dispatch({
        type: ActionType.CLEAR_PRIVATE_CHATS_GET,
        payload
    })
}


export const setPrivateChatOffline = payload => dispatch => {
    dispatch({
        type: ActionType.SET_PRIVATE_CHAT_OFFLINE,
        payload
    })
}

export const setPrivateChatReconnecting = payload => dispatch => {
    dispatch({
        type: ActionType.SET_PRIVATE_CHAT_RECONNECTING,
        payload
    })
}

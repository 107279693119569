import React from 'react'

export const Polls = ({ fill, className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width="25" height="25" viewBox="0 0 25 25" fill={fill ? fill : '#bdbdbd'}>
            <g id="polls" transform="translate(17723.389 -17707.76)">
                <rect id="Rectangle_2255" data-name="Rectangle 2255" width="25" height="25" transform="translate(-17723.389 17707.76)" fill="none" />
                <g id="graph" transform="translate(-17745.988 17708)">
                    <path id="Path_2046" data-name="Path 2046" d="M179.628,207.325h-1.916a1.726,1.726,0,0,1-1.724-1.724V193.724A1.726,1.726,0,0,1,177.712,192h1.916a1.726,1.726,0,0,1,1.724,1.724V205.6A1.726,1.726,0,0,1,179.628,207.325Z" transform="translate(-144.72 -182.805)" />
                    <path id="Path_2047" data-name="Path 2047" d="M27.628,283.494H25.712a1.726,1.726,0,0,1-1.724-1.724v-8.046A1.726,1.726,0,0,1,25.712,272h1.916a1.726,1.726,0,0,1,1.724,1.724v8.046A1.726,1.726,0,0,1,27.628,283.494Z" transform="translate(0 -258.973)" />
                    <path id="Path_2048" data-name="Path 2048" d="M285.9,24.521h-1.916a1.726,1.726,0,0,1-1.724-1.724V6.9h-1.053a1.245,1.245,0,0,1-.945-2.056l.011-.013,4.1-4.573a.766.766,0,0,1,1.141,0l4.1,4.573.011.013A1.245,1.245,0,0,1,288.68,6.9h-1.053V22.8A1.726,1.726,0,0,1,285.9,24.521Z" transform="translate(-243.716)" />
                </g>
            </g>
        </svg>
    )
}


import React from 'react'

export const Home = ({ fill, className }) => {
    return (
        <svg id="Group_2591" data-name="Group 2591" className={className} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill={fill ? fill : '#bdbdbd'} viewBox="0 0 25 25">
            <rect id="Rectangle_2245" data-name="Rectangle 2245" width="25" height="25" fill="none" />
            <path id="internet_1_" data-name="internet (1)" d="M24.111,11.074,12.78.213a.765.765,0,0,0-1.059,0L.378,11.086A1.294,1.294,0,0,0,0,11.994,1.278,1.278,0,0,0,1.276,13.27H3.062v9.7A1.532,1.532,0,0,0,4.594,24.5H8.932a.766.766,0,0,0,.766-.766V17.1a.256.256,0,0,1,.255-.255h4.594a.257.257,0,0,1,.255.255v6.635a.766.766,0,0,0,.766.766h4.339a1.532,1.532,0,0,0,1.531-1.531v-9.7h1.786A1.278,1.278,0,0,0,24.5,11.994a1.3,1.3,0,0,0-.389-.92Zm0,0" transform="translate(0.25 0.25)" />
        </svg>
    )
}

import React, { useState, useEffect, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Spinner } from 'react-bootstrap'


import { Chat as ChatComponent } from '../../components'
import { randomString, socket } from '../../helpers'
import { getPrivateChats, privateChatLoader, sendPrivateMessage, setPrivateChatActive, leavePrivateRoom, joinedPrivateChat, loadPreviousPrivateMessages } from '../../actions'

/**
* @author
* @function PrivateChat
**/

export const PrivateChat = memo(({ toUserId, isOrganizer }) => {

    const dispatch = useDispatch();
    const [initPrivateChatSocket, setInitPrivateChatSocket] = useState(true);
    const event = useSelector(state => state.event);
    const user = useSelector(state => state.user);
    const chats = useSelector(state => state.privateChats);


    useEffect(() => {
        dispatch(getPrivateChats({ eventId: event.id, isOrganizer, userId: toUserId, pageno: chats.pagination.page, pageSize: chats.pagination.pageSize }));
        dispatch(setPrivateChatActive(true));
        return () => {
            dispatch(leavePrivateRoom());
            setInitPrivateChatSocket(true);
        };
    }, [toUserId]);


    useEffect(() => {
        if (!chats.loading && user.isLoggedIn && !chats.joinedRoom && initPrivateChatSocket) {
            socket.emit('join-room', { room_id: chats.conversationId, public_room_id: event.conversationId, token: user.token, type: 'PRIVATE', source: event.conversationSource }, (error) => {
                if (error) {
                    dispatch(error({ message: error }));
                }
            });
            dispatch(joinedPrivateChat(toUserId));
            setInitPrivateChatSocket(false);
        }
    }, [chats.loading]);

    const loadMoreMessages = (type, value) => {
        if (!chats.pagination.isLast) {
            dispatch(privateChatLoader(true));
            if (type === 'messageId')
                dispatch(getPrivateChats({ conversationId: chats.conversationId, isOrganizer, userId: toUserId, eventId: event.id, messageId: value, pageSize: chats.pagination.pageSize * 2 }));
            else
                dispatch(getPrivateChats({ conversationId: chats.conversationId, isOrganizer, userId: toUserId, eventId: event.id, pageNo: value, pageSize: chats.pagination.pageSize * 2 }));
        }
    }

    const SendMessage = (message) => {
        socket.emit('send-message', {
            room_id: chats.conversationId,
            receiverId: toUserId,
            token: user.token,
            message,
            source: event.conversationSource,
            name: user.name,
            email: user.email,
            type: 'PRIVATE',
            public_room_id: event.conversationId,
        }, (error) => {
            if (error) {
                alert(error);
            }
        });

        let tempMessage = {
            _id: randomString(15),
            isSender: true,
            createdTime: new Date(),
            author: user.attendeeId,
            body: message,
            attendee: {
                contact: {
                    email: user.email,
                    displayName: user.name
                }
            }
        }
        dispatch(sendPrivateMessage(tempMessage));
    }

    const getMessagesAfterReconnection = () => {
        dispatch(privateChatLoader(true));
        dispatch(loadPreviousPrivateMessages({ conversationId: chats.conversationId, isOrganizer, userId: toUserId, eventId: event.id, pageNo: 1, pageSize: chats.pagination.pageSize }));
    }

    const setChatIsActive = status => {
        dispatch(setPrivateChatActive(status));
    }

    return (
        <>
            {
                chats.joinedRoom ?
                    <ChatComponent
                        chatType="PRIVATE"
                        onSendMessage={SendMessage}
                        pagination={chats.pagination}
                        messages={chats.messages}
                        loading={chats.loading}
                        reconnecting={chats.isChatReconnecting}
                        loadMore={loadMoreMessages}
                        loadMessagesAfterReconnection={getMessagesAfterReconnection}
                        setChatIsActive={setChatIsActive}
                        showHeader={event.showHeader}
                    />
                    :
                    <div className="text-center pt-5 mt-5">
                        < Spinner animation="grow" variant="grey" />
                    </div>
            }
        </>
    )
});
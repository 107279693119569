import React, { useEffect, forwardRef, useImperativeHandle } from 'react'
import { useDispatch } from 'react-redux'

import { capitalize } from '../../helpers'
import { checkLatency } from '../../services'
import { error } from '../../actions'


export const JitsiMeet = forwardRef((props, ref) => {

    const { meet, user } = props;
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        joinMeetingFunc() {
            findNearestRegion();
        }
    }));

    useEffect(() => {
        if (!window.JitsiMeetExternalAPI)
            alert('Error ocuured !. Please refresh');
    }, []);

    const findNearestRegion = () => {
        let regionLatency = [];
        let nearestRegion = meet.regions[0].code;
        var count = 0;
        if (meet.regions && meet.regions.length > 1) {
            meet.regions.forEach((value, index) => {
                checkLatency(value.pingUrl, value.code)
                    .then((result1) => {
                        count = count + 1;
                        checkLatency(value.pingUrl, value.code)
                            .then((result2) => {
                                count = count + 1;
                                let latency = {
                                    region: value.code,
                                    latency:
                                        result1.timeElapsed > result2.timeElapsed
                                            ? result1.timeElapsed
                                            : result2.timeElapsed,
                                };
                                regionLatency.push(latency);
                                if (meet.regions.length * 2 === count) {
                                    let lowestLatency = regionLatency[0].latency;
                                    regionLatency.forEach((v, index) => {
                                        nearestRegion = lowestLatency >= v.latency ? v.region : nearestRegion;
                                    });
                                    startConference(nearestRegion);
                                }
                            })
                            .catch((error) => { });
                    })
                    .catch((error) => { });
            });
        } else {
            startConference(nearestRegion);
        }
    }

    const startConference = (userRegion) => {
        try {
            console.log('user Region : ' + userRegion);
            if (meet.config.deploymentInfo) {
                meet.config.deploymentInfo.userRegion = userRegion;
            }
            else {
                meet.config.deploymentInfo = {
                    userRegion: userRegion
                }
            }
            const domain = meet.details.domain;
            const options = {
                roomName: meet.details.roomId,
                jwt: meet.user.jwt,
                width: '100%',
                height: '100%',
                parentNode: document.getElementById('jitsi-container'),
                userInfo: {
                    email: meet.details.email,
                },
                onload: function ($scope) {
                    props.setJoined();
                },
                configOverwrite: meet.config,
                interfaceConfigOverwrite: {
                    APP_NAME: 'YepDesk Meet',
                    TOOLBAR_BUTTONS: meet.setting,
                    SHOW_WATERMARK_FOR_GUESTS: false,
                    DEFAULT_REMOTE_DISPLAY_NAME: capitalize(meet.user.role),
                    SHOW_JITSI_WATERMARK: false,
                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                    SETTINGS_SECTIONS: ['devices', 'calendar'],
                    MOBILE_APP_PROMO: true,
                }
            };

            const api = new window.JitsiMeetExternalAPI(domain, options);
            api.executeCommand('displayName', user.name);
            api.executeCommand('subject', meet.details.title);
            api.executeCommand('avatarUrl', meet.user.avatar);
            api.addEventListener('videoConferenceJoined', () => { });

            api.addEventListener('readyToClose', function () {
                props.leaveMeeting();
                api.dispose();
            });

            api.addEventListener("videoConferenceJoined", function (object) {
                let numberOfParticipantsInMeeting = api.getNumberOfParticipants();
                if (meet.details.meetLimit && numberOfParticipantsInMeeting > meet.details.meetLimit) {
                    api.dispose();
                    dispatch(error({ message: 'Meeting limit reached' }));
                    props.leaveMeeting();
                }
            });



        } catch (error) {
            console.error('Failed to load Jitsi API', error);
        }
    }

    return (
        <>
            {<div className={`${props.joined ? "jitsi-container joined" : "jitsi-container"}`} id="jitsi-container" ></div>}
        </>

    );
});



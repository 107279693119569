import { ActionType } from '../helpers/constants'

const INTIAL_STATE = {
    loading: true,
    participants: [],
    showPrivateChat: false,
    privateChatParticipant: {}
};

export function participants(state = INTIAL_STATE, { type, payload }) {
    switch (type) {
        case ActionType.PARTICIPANTS_GET:
            return {
                ...state,
                participants: [...state.participants, ...payload.participants],
                loading: false,
            };

        case ActionType.PARTICIPANTS_JOINED:
            state.participants.map(participant => {
                if (payload.includes(participant.id)) {
                    return participant.isActive = true;
                }
                return participant.isActive = false;
            })
            return {
                ...state,
                participants: [...state.participants],
            };

        case ActionType.PARTICIPANT_LEFT:
            state.participants.map(participant => {
                if (participant.id === payload)
                    return participant.isActive = false;
                return null;
            })
            return {
                ...state,
                participants: [...state.participants],
            };

        case ActionType.PARTICIPANT_READ_MESSAGE:
            state.participants.map(participant => {
                if (participant.id === payload)
                    return participant.unSeen = false;
                return null;
            })
            return {
                ...state,
                participants: [...state.participants],
            };

        case ActionType.SET_UNSEEN_MESSAGE:
            state.participants.map(participant => {
                if (participant.id === payload)
                    return participant.unSeen = true;
                return null;
            })
            return {
                ...state,
                participants: [...state.participants],
            };


        case ActionType.SHOW_PRIVATE_CHAT:
            return {
                ...state,
                showPrivateChat: payload,
            };

        case ActionType.SET_PRIVATE_CHAT_PARTICIPANT:
            return {
                ...state,
                privateChatParticipant: payload,
            };

        default:
            return state
    }
}


import { localStore } from '../helpers'

export const getAccessToken = (isNeeded, eventId) => {
    return new Promise((resolve, reject) => {
        if (isNeeded) {
            getItem('liveUsers', eventId)
                .then(response => {
                    if (response !== null) {
                        resolve(response);
                    } else {
                        resolve(false);
                    }
                })
                .catch(error => {
                    reject(error)
                });
        } else {
            resolve(true);
        }
    });
};

export const getItem = (key, eventId) => {
    return new Promise((resolve, reject) => {
        const users = localStore.get(key);
        if (users) {
            const currentUser = users[eventId];
            if (currentUser && currentUser.token) resolve(currentUser.token);
            else resolve(null);
        }
        else resolve(null);
    })
};
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Container, Col, Row, Image, Form, Button, Spinner } from 'react-bootstrap'
import { userLogin } from '../../actions'
import { formatFromToDate, localStore } from '../../helpers'
/**
* @author
* @function EventLoginPage
**/



export const EventLoginPage = (props) => {
    const dispatch = useDispatch();

    const [user, setUser] = useState({
        email: localStore.get('userFromUrl') && localStore.get('userFromUrl').email ? localStore.get('userFromUrl').email : '',
        password: localStore.get('userFromUrl') && localStore.get('userFromUrl').password ? localStore.get('userFromUrl').password : ''
    });

    localStore.remove('userFromUrl');

    const [errorMessage, setErrorMessage] = useState(null);
    const [hidden, sethidden] = useState(true);

    const event = useSelector(state => state.event);
    const { loading, error } = useSelector(state => state.user);

    const login = (e) => {
        e.preventDefault();
        dispatch(userLogin({ ...user, eventId: event.id }))
    }

    const formEmailFieldChange = (event) => {
        setErrorMessage(null)
        setUser({ ...user, email: event.target.value })
    }

    const formPasswordFieldChange = (event) => {
        setErrorMessage(null)
        setUser({ ...user, password: event.target.value })
    }

    useEffect(() => {
        if (error)
            setErrorMessage(error)
    }, [error]);

    const toggleShow = () => {
        sethidden(!hidden);
    }
    return (
        <Container className="event-login">
            <Row style={{ position: 'relative' }}>
                <Col md="7">
                    <Row className="event-section">
                        <Col sm={12}>
                            <Image src={event.coverImage} fluid className="event-cover" />
                        </Col>
                        <Col sm={12} className="pt-4">
                            <h1 className="event-name">
                                {event.name}
                            </h1>
                            <p className="event-organizer">
                                by {event.author}
                            </p>
                            <div className="event-date">
                                <Image src="/assets/icons/calendar.svg" fluid /><span>
                                    {formatFromToDate(event.startTime, event.endTime)}
                                </span>
                            </div>
                        </Col>
                        <br />
                    </Row>
                </Col>
                <Col md="5" className="login-container">
                    <div className="login-box">
                        <div >
                            <div className="login-title">
                                Login to your event </div>
                            <small className="login-sub-title">
                                Check your invitation email and find password!</small>
                        </div>
                        <div className="login-form-box">
                            <Form onSubmit={login}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Attendee Email</Form.Label>
                                    <Form.Control required type="email"
                                        onChange={(e) => formEmailFieldChange(e)}
                                        value={user.email}
                                        placeholder="Attendee email here" />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword" className="mb-0" style={{ position: 'relative' }}>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control required
                                        type={hidden ? "password" : "text"}
                                        onChange={(e) => formPasswordFieldChange(e)}
                                        value={user.password}
                                        autoComplete="new-password"
                                        placeholder="Password" />
                                    <label className="password-toggle" onClick={toggleShow}>{hidden ? 'Show' : 'Hide'}</label>
                                </Form.Group>
                                <div className="error-message">{errorMessage}</div>
                                <Form.Group className="pt-4 mt-1" >
                                    <Button disabled={loading} className="login-button" variant="primary" type="submit" size="lg">
                                        {loading ? <><Spinner className="login-spinner" animation="border" size="sm" /> <span className="p-1">Logging in</span></> : 'Login Now'}
                                    </Button>
                                </Form.Group>
                            </Form>
                            <div className="text-right">
                                {/* <small>Need help ?</small> */}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )

}

import React from 'react'

/**
* @author
* @function EventStreams
**/

export const EventStreams = (props) => {
    return (
        <div>EventStreams</div>
    )

}
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Chat, Participants, Polls } from '../../../assets/icons'

import './styles.scss'

/**
* @author
* @function Tabs
* @param {Boolean} changeUrl -  Tab type. If its 'true' then switching tabs change url.
* @param {Object} tabs - Tabs data object consist of value, label and key.
*/

//Note : Pass tab as child if changeUrl is false


export const Tabs = ({ changeUrl, tabs, children }) => {
    const history = useHistory()
    const [activeTab, setActiveTab] = useState(changeUrl ? '' : children[0].props.label);
    let content;
    let buttons = [];

    const changeTabLink = (tabs, tab) => {
        tabs.forEach(tab => {
            tab.isActive = false;
        });
        tab.isActive = true;
        history.push(tab.url)
    };

    const changeTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <>
            {changeUrl ? <div>
                <TabLinks tabs={tabs} changeTab={changeTabLink} />
            </div>
                : <div>
                    {React.Children.map(children, child => {
                        buttons.push(child.props.label)
                        if (child.props.label === activeTab) content = child.props.children
                    })}
                    <TabButtons activeTab={activeTab} buttons={buttons} changeTab={changeTab} />
                    <div className="tab-content">{content}</div>
                </div>
            }
        </>
    )
}

const TabLinks = ({ tabs, changeTab }) => {
    return (
        <div className="tab-buttons">
            {tabs.map(tab => {
                return <button className={tab.isActive ? 'tab-button active ' : 'tab-button'} key={tab.label} onClick={() => changeTab(tabs, tab)}>{tab.label}</button>
            })}
        </div>
    )
}

const TabButtons = ({ buttons, changeTab, activeTab }) => {
    return (
        <div className="tab-buttons">
            {buttons.map(button => {
                return <button className={button === activeTab ? 'tab-button active ' : 'tab-button'} key={button} onClick={() => changeTab(button)}>{button}</button>
            })}
        </div>
    )
}

export const Tab = ({ children }) => {
    return (
        <> {children} </>
    )
}

const getIcons = (item, activeColor) => {
    const color = item.isActive ? activeColor : '#bdbdbd';
    switch (item.key) {
        case 'chat':
            return <Chat fill={color} className="svg-icon sm" />
        case 'participants':
            return <Participants fill={color} className="svg-icon sm" />
        case 'polls':
            return <Polls fill={color} className="svg-icon sm" />
        default:
            return null;
    }
}

const renderActiveStatus = (item) => {
    return (<span className="activeStatus">
        {item.statusValue ? <>{item.statusValue}</> : null}
    </span>)
}


export const RoundedTabs = ({ menuItems, activeColor, content, setTabActive, hideTabs }) => {
    return (
        <>
            {!hideTabs &&
                <div className="tabs-box">
                    {
                        menuItems && menuItems.length && menuItems.map((tab, index) => (
                            <button onClick={() => setTabActive(tab.key)} key={tab.key} className={tab.isActive ? 'tab-round active ' : 'tab-round'}>
                                {getIcons(tab, activeColor)}
                                <span className="tab-label">
                                    {tab.label}{tab.length && tab.length > 0 ? ' (' + tab.length + ')' : null}
                                </span>
                                {tab.status === 'ACTIVE'&& tab.key === 'chat' && renderActiveStatus(tab)}
                            </button>
                        ))
                    }
                </div>
            }
            <div className="tab-content-box">
                {content()}
            </div>
        </>
    )
}

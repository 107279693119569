import React, { useEffect, memo } from 'react'
import { useDispatch } from 'react-redux'

import { Chat as ChatComponent } from '../../components'
import { getGroupChats, groupChatLoader, setChatRead, setGroupChatActive, loadPreviousGroupMessages } from '../../actions'

/**
* @author
* @function GroupChat
**/

export const GroupChat = memo(({ SendMessage, chats, event }) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setChatRead());
        dispatch(setGroupChatActive(true));
        return () => {
            dispatch(setGroupChatActive(false));
            dispatch(loadPreviousGroupMessages({ conversationId: event.conversationId, eventId: event.id, pageNo: 1, pageSize: chats.pagination.pageSize }));
        }
    }, []);

    const loadMoreMessages = (type, value) => {
        if (!chats.pagination.isLast) {
            dispatch(groupChatLoader(true));
            if (type === 'messageId')
                dispatch(getGroupChats({ conversationId: event.conversationId, eventId: event.id, messageId: value, pageSize: chats.pagination.pageSize * 2 }));
            else
                dispatch(getGroupChats({ conversationId: event.conversationId, eventId: event.id, pageNo: value, pageSize: chats.pagination.pageSize * 2 }));

        }
    }

    const getMessagesAfterReconnection = () => {
        dispatch(groupChatLoader(true));
        dispatch(loadPreviousGroupMessages({ conversationId: event.conversationId, eventId: event.id, pageNo: 1, pageSize: chats.pagination.pageSize }));
    }

    const setChatIsActive = status => {
        dispatch(setGroupChatActive(status));
        if (status)
            dispatch(setChatRead());
    }

    return (
        <ChatComponent
            onSendMessage={SendMessage}
            pagination={chats.pagination}
            messages={chats.messages}
            loading={chats.loading}
            reconnecting={chats.isChatReconnecting}
            loadMore={loadMoreMessages}
            loadMessagesAfterReconnection={getMessagesAfterReconnection}
            setChatIsActive={setChatIsActive}
            showHeader={event.showHeader}
        />
    )
});

import { ActionType } from '../helpers'

export const success = message => {
    return {
        type: ActionType.TOAST_SUCCESS,
        payload: message
    };
}

export const error = message => dispatch => {
    dispatch({
        type: ActionType.TOAST_ERROR,
        payload: message
    })
}

// export const error = message => {
//     return {
//         type: ActionType.TOAST_ERROR,
//         payload: message
//     };
// }

export const warning = message => {
    return {
        type: ActionType.TOAST_WARNING,
        payload: message
    };
}

export const info = message => {
    return {
        type: ActionType.TOAST_INFO,
        payload: message
    };
}


export const privateMessage = message => dispatch => {
    dispatch({
        type: ActionType.TOAST_PRIVATE_MESSAGE,
        payload: message
    })
}

export const clear = () => {
    return {
        type: ActionType.TOAST_CLEAR
    };
}

import React from 'react'

export const Participants = ({ fill, className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width="25" height="25" viewBox="0 0 25 25" fill={fill ? fill : '#bdbdbd'}>
            <g id="participants" transform="translate(17637.172 -17781.875)">
                <rect id="Rectangle_2254" data-name="Rectangle 2254" width="25" height="25" transform="translate(-17637.172 17781.875)" fill="none" />
                <g id="user" transform="translate(-17637 17777.652)">
                    <path id="Path_2045" data-name="Path 2045" d="M14.88,9.91a4.481,4.481,0,0,1,2.085,3.329A3.615,3.615,0,1,0,14.88,9.91Zm-2.369,7.4A3.616,3.616,0,1,0,8.894,13.7,3.616,3.616,0,0,0,12.511,17.314Zm1.534.246H10.976a4.636,4.636,0,0,0-4.63,4.631v3.753l.01.059.258.081a21.077,21.077,0,0,0,6.3,1.015,12.925,12.925,0,0,0,5.5-1.032l.242-.122h.026V22.191A4.634,4.634,0,0,0,14.045,17.561Zm5.983-3.733H16.983a4.457,4.457,0,0,1-1.376,3.1,5.5,5.5,0,0,1,3.93,5.265v1.156a12.461,12.461,0,0,0,4.853-1.02l.242-.122h.026V18.457A4.635,4.635,0,0,0,20.028,13.827ZM6.165,13.581a3.592,3.592,0,0,0,1.923-.558,4.476,4.476,0,0,1,1.682-2.854c0-.068.01-.135.01-.2a3.616,3.616,0,1,0-3.616,3.616Zm3.248,3.351a4.46,4.46,0,0,1-1.375-3.087c-.113-.008-.225-.017-.34-.017H4.63A4.636,4.636,0,0,0,0,18.457v3.753l.01.058.258.082a21.71,21.71,0,0,0,5.214.98V22.2A5.5,5.5,0,0,1,9.413,16.932Z" />
                </g>
            </g>
        </svg>
    )
}
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import toaster from 'toasted-notes'
import { Offline } from 'react-detect-offline'

import { showPrivateChatFunc, hideTabs, expandRightNav, setTabActive, setPrivateChatParticipant } from '../../actions'
import { Routes } from '../../routes'
import { Body } from '../../components'
import { OfflineToastr, Toastr } from '../../components/UI'

window.addEventListener('storage', function (e) {
    if (e.key === 'liveUsers') {
        window.location.reload();
    }
});

export const App = () => {

    const event = useSelector(state => state.event);
    const { participants } = useSelector(state => state.participants);
    const toast = useSelector(state => state.toast);
    const dispatch = useDispatch();

    useEffect(() => {
        if (toast.message) {
            if (toast.type === 'private_message') {
                toaster.notify(({ onClose }) => (
                    <Toastr message={toast.message} type={toast.type} title={toast.title} onClickFunc={() => onToastrClick(onClose)} />
                ), {
                    duration: toast.duration,
                    position: toast.position
                });
            }
            else {
                toaster.notify(<Toastr message={toast.message} type={toast.type} title={toast.title} />, {
                    duration: toast.duration,
                    position: toast.position
                });
            }
        }
    }, [toast]);

    const onToastrClick = (onClose) => {
        if (toast.type === 'private_message') {
            let sender = participants.find(participant => participant.id === toast.data.id);
            if (sender) {
                dispatch(expandRightNav(true));
                dispatch(hideTabs(true));
                dispatch(showPrivateChatFunc(true));
                dispatch(setTabActive('participants'));
                dispatch(setPrivateChatParticipant(sender));
                onClose();
            }
        }
    }

    return (
        <>
            <Body hasHeader={event.showHeader}>
                <Routes />
                <Offline>
                    <OfflineToastr showToastr={true} />
                </Offline>
            </Body>
        </>
    );
}



import { API, GET, NODE_URL } from '../helpers'

export const getGroupChatsFunc = (params, dispatch) => {
    return new Promise((resolve, reject) => {
        const url = NODE_URL + API.GROUP_CHATS_GET + params.conversationId;
        GET(url, params, {}, {}, true, true, params.eventId
        ).then(({ status, result, message }) => {
            if (status === "success") {
                resolve({ status, data: result, message });
            } else if (status === "error") {
                reject({ status: "error", message: message });
            }
        })
            .catch(function (error) {
                reject(error);
            });
    });
};

export const getPrivateChatsFunc = (params, dispatch) => {
    return new Promise((resolve, reject) => {
        const url = NODE_URL + API.PRIVATE_CHATS_GET + params.userId
        GET(url, params, {}, {}, true, true, params.eventId
        ).then(({ status, result, message }) => {
            if (status === "success") {
                resolve({ status, data: result, message });
            } else if (status === "error") {
                reject({ status: "error", message: message });
            }
        })
            .catch(function (error) {
                reject(error);
            });
    });
};


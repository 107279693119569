import { ActionType } from '../helpers/constants'

const INTIAL_STATE = {
    loading: true,
    messages: [],
    isActive: false,
    isChatOffline: false,
    isChatReconnecting: false,
    joinedRoom: false,
    pagination: {
        isLast: false,
        page: 1,
        pageSize: 10
    },
};

export function groupChats(state = INTIAL_STATE, { type, payload }) {
    switch (type) {
        case ActionType.GROUP_CHATS_GET:
            return {
                ...state,
                messages: [...state.messages, ...payload.messages],
                pagination: {
                    isLast: payload.isLast,
                    page: payload.page,
                    pages: payload.pages,
                    total: payload.total,
                    pageSize: state.pagination.pageSize,
                },
                loading: false,
            };

        case ActionType.LOAD_PREVIOUS_GROUP_MESSAGES:
            return {
                ...state,
                messages: [...payload.messages],
                pagination: {
                    isLast: payload.isLast,
                    page: payload.page,
                    pages: payload.pages,
                    total: payload.total,
                    pageSize: state.pagination.pageSize,
                },
                loading: false,
            };
        case ActionType.CLEAR_PREVIOUS_GROUP_MESSAGES:
            let tempArray = state.messages.slice(0, payload);
            return {
                ...state,
                messages: [...tempArray],
                pagination: {
                    isLast: false,
                    page: 1,
                    pageSize: 10
                },
                loading: false,
            };
        case ActionType.SEND_GROUP_MESSAGE:
            return {
                ...state,
                messages: [payload, ...state.messages],
            };
        case ActionType.GROUP_JOINED_ROOM:
            return {
                ...state,
                joinedRoom: payload,
            };
        case ActionType.GROUP_CHAT_LOADER:
            return {
                ...state,
                loading: payload,
            };

        case ActionType.SET_GROUP_CHAT_ACTIVE:
            return {
                ...state,
                isActive: payload,
            };
        case ActionType.SET_GROUP_CHAT_OFFLINE:
            return {
                ...state,
                isChatOffline: payload,
            };

        case ActionType.SET_GROUP_CHAT_RECONNECTING:
            return {
                ...state,
                isChatReconnecting: payload,
            };

        default:
            return state
    }
}




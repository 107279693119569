import { ActionType } from '../helpers/constants'

const INTIAL_STATE = {
    loading: true,
    details: {},
    user: {
        initMeeting: true,
        joiningMeeting: false,
        joinedMeeting: false,
        leftMeeting: false,
        rejoiningMeeting: false,
    },
    setting: [],
    config: {}
};

export function meet(state = INTIAL_STATE, { type, payload }) {
    switch (type) {
        case ActionType.MEET_DETAILS_GET:
            return {
                ...state,
                ...payload,
                user: {
                    ...state.user, ...payload.user
                },
                loading: false,
            };
        case ActionType.MEET_LOADER:
            return {
                ...state,
                loading: payload
            };
        case ActionType.INIT_MEETING:
            return {
                ...state,
                user: {
                    ...state.user,
                    initMeeting: payload,
                    joiningMeeting: false,
                    joinedMeeting: false,
                    leftMeeting: false,
                    rejoiningMeeting: false,
                },
            };
        case ActionType.JOIN_MEETING:
            return {
                ...state,
                user: {
                    ...state.user,
                    initMeeting: false,
                    joiningMeeting: payload
                }
            };
        case ActionType.JOINED_MEETING:
            return {
                ...state,
                user: {
                    ...state.user,
                    joiningMeeting: false,
                    rejoiningMeeting: false,
                    joinedMeeting: payload
                }
            };
        case ActionType.LEFT_MEETING:
            return {
                ...state,
                user: {
                    ...state.user,
                    joiningMeeting: false,
                    joinedMeeting: false,
                    rejoiningMeeting: false,
                    leftMeeting: payload
                }
            };
        case ActionType.REJOIN_MEETING:
            return {
                ...state,
                user: {
                    ...state.user,
                    joiningMeeting: false,
                    joinedMeeting: false,
                    leftMeeting: false,
                    rejoiningMeeting: payload
                }
            };
        default:
            return state
    }
}
import { ActionType, playNotificationSound } from '../helpers'
import { getParticipantsFunc } from '../services'
import { error } from '../actions'

export const getParticipants = params => dispatch => {
    getParticipantsFunc(params, dispatch)
        .then(response => {
            dispatch({
                type: ActionType.PARTICIPANTS_GET,
                payload: response.data
            })

            dispatch({
                type: ActionType.SET_PARTICIPANTS_COUNT,
                payload: response.data && response.data.participants ? response.data.participants.length : 0
            })

            response.data.participants.map(participant => {
                if (participant.unSeen) {
                    dispatch({
                        type: ActionType.SET_PARTICIPANTS_STATE,
                        payload: 'ACTIVE',
                    })
                    return;
                }
            })
        })
        .catch(function (e) {
            dispatch(error({ message: e.message ? e.message : 'Error Occured!' }));
        });
}

export const showPrivateChatFunc = payload => dispatch => {
    dispatch({
        type: ActionType.SHOW_PRIVATE_CHAT,
        payload
    })
}

export const setPrivateChatParticipant = payload => dispatch => {
    dispatch({
        type: ActionType.SET_PRIVATE_CHAT_PARTICIPANT,
        payload
    })
}

export const participantsJoined = payload => dispatch => {
    dispatch({
        type: ActionType.PARTICIPANTS_JOINED,
        payload
    })
}


export const participantLeft = payload => dispatch => {
    dispatch({
        type: ActionType.PARTICIPANT_LEFT,
        payload
    })
}

export const privatePushMessage = payload => (dispatch, getState) => {

    if (payload.userId !== getState().privateChats.userId) {
        playNotificationSound();
        dispatch({
            type: ActionType.SET_UNSEEN_MESSAGE,
            payload: payload.userId
        })
        dispatch({
            type: ActionType.SET_PARTICIPANTS_STATE,
            payload: 'ACTIVE',
        })
        dispatch({
            type: ActionType.TOAST_PRIVATE_MESSAGE,
            payload: {
                message: payload.message,
                title: payload.name,
                userId: payload.userId,
            },
        })
    }
}



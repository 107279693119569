import React from 'react'

export const Meeting = ({ fill, className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25.078" className={className} height="25" viewBox="0 0 25.078 25" fill={fill ? fill : '#bdbdbd'}>
            <g id="meeting" transform="translate(0 0.19)">
                <rect id="Rectangle_2248" data-name="Rectangle 2248" width="25" height="25" transform="translate(0 -0.19)" fill="none" />
                <g id="meeting_1_" data-name="meeting (1)" transform="translate(0 1.975)" >
                    <path id="Path_2033" data-name="Path 2033" d="M152.3,77.609a4.616,4.616,0,1,0,4.626,4.616A4.626,4.626,0,0,0,152.3,77.609Z" transform="translate(-139.757 -75.433)" />
                    <path id="Path_2034" data-name="Path 2034" d="M43.218,38.133a2.855,2.855,0,1,0,1.473,5.3,6.146,6.146,0,0,1,1.38-2.661A2.862,2.862,0,0,0,43.218,38.133Z" transform="translate(-38.194 -38.072)" />
                    <path id="Path_2035" data-name="Path 2035" d="M322.313,42.291a2.854,2.854,0,1,0-1.366-2.594A6.156,6.156,0,0,1,322.313,42.291Z" transform="translate(-303.749 -37)" />
                    <path id="Path_2036" data-name="Path 2036" d="M100.733,262.385a6.1,6.1,0,0,1-2.473.882v4.874H96.677v-4.874a6.1,6.1,0,0,1-2.473-.882,7.691,7.691,0,0,0-4.466,7.014v2.021H105.2V269.4A7.691,7.691,0,0,0,100.733,262.385Z" transform="translate(-84.929 -250.308)" />
                    <g id="Group_2594" data-name="Group 2594" transform="translate(0 6.762)">
                        <path id="Path_2037" data-name="Path 2037" d="M0,168.8H6.03a9.865,9.865,0,0,1,1.915-1.423,6.2,6.2,0,0,1-1.6-3.8,4.445,4.445,0,0,1-3.523-.382A4.985,4.985,0,0,0,0,167.706Z" transform="translate(0 -163.199)" />
                    </g>
                    <g id="Group_2595" data-name="Group 2595" transform="translate(17.133 6.703)">
                        <path id="Path_2038" data-name="Path 2038" d="M321.585,167.7h6.093v-1.1a4.983,4.983,0,0,0-2.818-4.505,4.463,4.463,0,0,1-3.52.388,6.185,6.185,0,0,1-1.607,3.853A11.76,11.76,0,0,1,321.585,167.7Z" transform="translate(-319.733 -162.096)" />
                    </g>
                </g>
            </g>
        </svg>
    )
}
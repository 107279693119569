import { ActionType } from '../helpers/constants'

const INTIAL_STATE = {
    showHeader: false,
    showFooter: false,
    showLeftNav: false,
    showRightNav: false,
    expandRightNav: false,
    expandLeftNav: false,
    isOffline: false,
    hideTabs: false,
};

export function root(state = INTIAL_STATE, { type, payload }) {
    switch (type) {
        case ActionType.SHOW_HEADER:
            return {
                ...state,
                showHeader: payload,
            };
        case ActionType.SHOW_FOOTER:
            return {
                ...state,
                showFooter: payload,
            };
        case ActionType.SET_OFFLINE:
            return {
                ...state,
                isOffline: payload,
            };
        case ActionType.SHOW_LEFT_NAV:
            return {
                ...state,
                showLeftNav: payload,
            };
        case ActionType.SHOW_RIGHT_NAV:
            return {
                ...state,
                showRightNav: payload,
            };
        case ActionType.EXPAND_LEFT_NAV:
            return {
                ...state,
                expandLeftNav: payload,
            };
        case ActionType.EXPAND_RIGHT_NAV:
            return {
                ...state,
                expandRightNav: payload,
            };
        case ActionType.HIDE_TABS:
            return {
                ...state,
                hideTabs: payload,
            };
        default:
            return state
    }
}
import React from 'react'


import { UserAvatar } from '../userAvatar'
import { Chat } from '../../../assets/icons'

import './styles.scss'

/**
* @author
* @function UserCard
**/

export const UserCard = ({ index, user, privateMessage, showMessageIcon }) => {

    const renderActiveStatus = (user) => {
        return (<div className="activeStatus">
            <span></span>
        </div>)
    }

    return (
        <div className="user-card">
            <div className="user-card-box">
                <div>
                    <UserAvatar
                        user={user}
                        colorIndex={index}
                        height={35}
                    />
                </div>
                <div className="user-details-box">
                    <div className="user-primary ellipsis">
                        {user.name}
                    </div>
                    <div className="user-secondary ellipsis">
                        {user.email}
                    </div>
                </div>
                {showMessageIcon &&
                    <div className="user-chat-box" onClick={() => privateMessage()}>
                        <Chat className="chat-icon" />
                        {user.unSeen && renderActiveStatus(user)}
                    </div>
                }

            </div>
        </div>
    )
}

import { ActionType } from '../helpers/constants'

const INTIAL_STATE = {
    loading: true,
    showHeader: false,
    showFooter: false,
    user: {},
    speakers: [],
    mainMenu: [],
    secondaryMenu: []
};

export function event(state = INTIAL_STATE, { type, payload }) {
    const chatIndex = state.secondaryMenu.findIndex(element => element.key === 'chat');
    const participantsIndex = state.secondaryMenu.findIndex(element => element.key === 'participants');

    switch (type) {
        case ActionType.SHOW_HEADER:
            return {
                ...state,
                showHeader: payload,
            };

        case ActionType.SHOW_FOOTER:
            return {
                ...state,
                showFooter: payload,
            };

        case ActionType.SET_MENU_ACTIVE:
            state.mainMenu.forEach(menu => {
                if (menu.key === payload) menu.isActive = true;
                else menu.isActive = false;
            });
            return { ...state };

        case ActionType.SET_TAB_ACTIVE:
            state.secondaryMenu.forEach(menu => {
                if (menu.key === payload) menu.isActive = true;
                else menu.isActive = false;
            });
            return { ...state };

        case ActionType.EVENT_DETAILS_GET:
            return {
                ...state, ...payload, loading: false
            };

        case ActionType.SET_CHAT_UNREAD:
            state.secondaryMenu[chatIndex] = { ...state.secondaryMenu[chatIndex], status: 'ACTIVE', statusValue: state.secondaryMenu[chatIndex].statusValue + 1 }
            return {
                ...state
            };

        case ActionType.SET_PARTICIPANTS_STATE:
            state.secondaryMenu[participantsIndex] = { ...state.secondaryMenu[participantsIndex], status: payload }
            return {
                ...state
            };

        case ActionType.SET_PARTICIPANTS_COUNT:
            state.secondaryMenu[participantsIndex] = { ...state.secondaryMenu[participantsIndex], length: payload }
            return {
                ...state
            };

        case ActionType.SET_CHAT_READ:
            state.secondaryMenu[chatIndex] = { ...state.secondaryMenu[chatIndex], status: 'INACTIVE', statusValue: 0 }
            return {
                ...state
            };

        default:
            return state
    }
}
import React from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from "react-router-dom";

import './styles.scss'

export const ErrorPage = (props) => {
    let { errorCode } = useParams();
    return (
        <Container className="text-center error-page" >
            <div className='error-box'>
                <div className="error-code">
                    {props.errorCode ? props.errorCode : errorCode}
                </div>
                <div className='error-description'>
                    Sorry but we couldn't find this page.
                </div>
            </div>
        </Container>
    )
}

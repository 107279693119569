import React from 'react'

export const Stream = ({ fill, className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" className={className} height="25" viewBox="0 0 25 25" fill={fill ? fill : '#bdbdbd'}>
            <g id="stream" transform="translate(0 0.136)">
                <rect id="Rectangle_2246" data-name="Rectangle 2246" width="25" height="25" transform="translate(0 -0.136)" fill="none" />
                <g id="film" transform="translate(0 4.885)">
                    <path id="Path_2026" data-name="Path 2026" d="M16,0H1.72A1.73,1.73,0,0,0,0,1.735v11.42a1.73,1.73,0,0,0,1.72,1.735H16a1.73,1.73,0,0,0,1.72-1.735V1.735A1.73,1.73,0,0,0,16,0Zm0,0" />
                    <path id="Path_2027" data-name="Path 2027" d="M328.906,40.161l6.1,3.341V31.981l-6.1,3.341Zm0,0" transform="translate(-310.007 -30.279)" />
                </g>
            </g>
        </svg>

    )
}
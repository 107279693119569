import React from 'react';
import { Navbar, Nav, NavDropdown, Container, Image } from 'react-bootstrap'

import { extractNumberFromString, getFirstName, logout } from '../../helpers'
import { UserAvatar } from '../UI'

import './styles.scss'

export const Header = () => {
    return (
        <Navbar bg="white" expand="lg" fixed="top">
            <Container>
                <Navbar.Brand href="/">YepDesk header</Navbar.Brand>
            </Container>
        </Navbar>
    );
}

export const EventHeader = ({ event, user, height }) => {
    return (
        <Navbar collapseOnSelect fixed="top" expand="lg" className="event-header">
            <div className="nav-box">
                <Navbar.Brand>{event.logo && <Image className="logo" alt="logo" src={event.logoSmall ? event.logoSmall : event.logo} />}{event.name}</Navbar.Brand>
                <Nav>
                    {/* <Nav.Link>
                        <Image src="/assets/icons/header-calendar.svg" />
                    </Nav.Link>
                    <Nav.Link>
                        <Image src="/assets/icons/bell.svg" />
                    </Nav.Link> */}
                    <Nav.Link className='user-name'>
                        {user.name && <>Hi {getFirstName(user.name)}</>}
                    </Nav.Link>
                    <NavDropdown alignRight
                        title={
                            <UserAvatar user={user} colorIndex={user.attendeeId ? extractNumberFromString(user.attendeeId) : 0} height={height} className="header-avatar" />
                        }
                        id="basic-nav-dropdown">
                        {/* <NavDropdown.Item >Profile</NavDropdown.Item> */}
                        <NavDropdown.Item onClick={() => logout(event.id)}>Logout
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>

            </div>

        </Navbar >
    );
}
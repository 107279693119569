import React from 'react'
import variables from '../../../variables.scss'
import { Error, Success } from '../../../assets/icons'

import './styles.scss'

export const OfflineToastr = ({ showToastr }) => {
    return (
        <>
            {showToastr ? <div className="offline-toastr"> You're offline right now. Check your connection.</div> : null}
        </>
    )
}

const renderToastr = (type, message, title, onClickFunc) => {
    switch (type) {
        case 'success':
            return (
                <div className="toaster-box" style={{ borderLeft: `3px solid ${variables.sucessColor}` }}>
                    <Success fill={variables.sucessColor} className="toaster-icon" />
                    <label className="toaster-title" style={{ color: variables.sucessColor }}>{title}</label><br />
                    <div className="toaster-message">{message}</div>
                </div>
            );
        case 'error':
            return (
                <div className="toaster-box" style={{ borderLeft: `3px solid ${variables.dangerColor}` }}>
                    <Error fill={variables.dangerColor} className="toaster-icon" />
                    <label className="toaster-title" style={{ color: variables.dangerColor }}>{title}</label><br />
                    <div className="toaster-message">{message}</div>
                </div>
            );
        case 'private_message':
            return (
                <div className="toaster-box toaster-message-box" onClick={onClickFunc} style={{ borderLeft: `3px solid ${variables.primaryColor}`, }}>
                    <label className="toaster-title" style={{ color: variables.primaryColor }}>{title}</label><br />
                    <div className="toaster-message ellipsis">{message}</div>
                </div>
            );
        default:
            return (
                <div className="toaster-box" style={{ borderLeft: `3px solid ${variables.primaryColor}` }}>
                    <label className="toaster-title" style={{ color: variables.primaryColor }}>{title}</label><br />
                    <div className="toaster-message">{message}</div>
                </div>
            );
    }
}


export const Toastr = ({ type, message = '', title = '', onClickFunc }) => (
    renderToastr(type, message, title, onClickFunc)
);
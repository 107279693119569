import { ActionType } from '../helpers'
import { getMeetDetailsFunc } from '../services'
import { error } from '../actions'

export const getMeetDetails = params => dispatch => {
    getMeetDetailsFunc(params)
        .then(response => {
            dispatch({
                type: ActionType.MEET_DETAILS_GET,
                payload: response.data
            })
        })
        .catch(function (e) {
            dispatch(error({ message: e.message ? e.message : 'Error Occured!' }));
        });
}

export const meetLoader = (data) => dispatch => {
    dispatch({
        type: ActionType.MEET_LOADER,
        payload: data
    })
}

export const initMeetingAction = (data) => dispatch => {
    dispatch({
        type: ActionType.INIT_MEETING,
        payload: data
    })
}

export const joinMeetingAction = (data) => dispatch => {
    dispatch({
        type: ActionType.JOIN_MEETING,
        payload: data
    })
}

export const rejoinMeetingAction = (data) => dispatch => {
    dispatch({
        type: ActionType.REJOIN_MEETING,
        payload: data
    })
}

export const joinedMeetingAction = (data) => dispatch => {
    dispatch({
        type: ActionType.JOINED_MEETING,
        payload: data
    })
}

export const leftMeetingAction = (data) => dispatch => {
    dispatch({
        type: ActionType.LEFT_MEETING,
        payload: data
    })
}
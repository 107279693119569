import convert from 'color-convert'
import { localStore } from './'
export const getUserInitials = name => {
    var initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    if (initials.length < 2 && name.length > 1) {
        initials = initials + name[1].toUpperCase();
    }
    return initials;
};


export const getGradientRandomColors = [
    ['#62cbfc', '#2594ef'],
    ['#db96f1', '#d05eea'],
    ['#47e8ce', '#24c2ae'],
    ['#ffc35e', '#ff9e52'],
    ['#74a2ff', '#5b55ff'],
];

export const getRandomColors = [
    '#db96f1', '#F4A460', '#74a2ff', '#66CDAA', '#009FD4', '#B381B3',
    '#939393', '#D47500', '#FF5454', '#FF8C00', '#B8860B', '#4682B4',
    '#DB7093', '#008080', '#E9967A', '#4169E1', '#9966CC', '#808000',
    '#A0522D', '#5F9EA0'
];

export const capitalize = (string = '') => {
    let str = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    return str
};

export const logout = (eventId) => {
    let usersInLocalStorage = localStore.get('liveUsers');
    if (usersInLocalStorage) {
        const currentUser = usersInLocalStorage[eventId];
        if (currentUser) {
            delete usersInLocalStorage[eventId];
            localStore.set('liveUsers', usersInLocalStorage)
            window.location.reload();
        }
    }
};


export const getFirstName = (string = '') => {
    let firstName = string.split(" ", 2);
    return firstName[0];
};

export const getWhenWasTheDate = (dateTime) => {
    let date = new Date(dateTime);
    let now = new Date();
    // date.setHours(0, 0, 0, 1);
    // now.setHours(0, 0, 0, 1);
    if (date > now)
        return "UPCOMING";
    return "PAST";
};

export const playNotificationSound = () => {
    const audio = new Audio('/assets/me-too.ogg');
    audio.play();
};

export const extractNumberFromString = (string = '') => {
    let numb = string.match(/\d/g);
    numb = numb && numb.join("");
    return numb ? parseInt(numb) : 0;
};

export const randomString = (length = 5) => {
    var text = "";
    var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
};


export const formatFromToDate = (fromDateTime, toDateTime, isShort = true) => {
    if (toDateTime) {
        if (
            getYearFromDate(fromDateTime, isShort) ===
            getYearFromDate(toDateTime, isShort) &&
            getMonthFromDate(fromDateTime, isShort) ===
            getMonthFromDate(toDateTime, isShort) &&
            getDayFromDate(fromDateTime, isShort) ===
            getDayFromDate(toDateTime, isShort)
        ) {
            return (
                getMonthFromDate(fromDateTime, isShort) +
                " " +
                getDayFromDate(fromDateTime, isShort) +
                ", " +
                getYearFromDate(fromDateTime, isShort) +
                " " +
                getTimeFromDate(fromDateTime, isShort) +
                " - " +
                getTimeFromDate(toDateTime, isShort)
            );
        }
        else {
            return (
                getMonthFromDate(fromDateTime, isShort) +
                " " +
                getDayFromDate(fromDateTime, isShort) +
                ", " +
                getYearFromDate(fromDateTime, isShort) +
                " " +
                getTimeFromDate(fromDateTime, isShort) +
                " - " +
                getMonthFromDate(toDateTime, isShort) +
                " " +
                getDayFromDate(toDateTime, isShort) +
                ", " +
                getYearFromDate(toDateTime, isShort) +
                " " +
                getTimeFromDate(toDateTime, isShort)
            );
        }
    } else {
        return (
            getMonthFromDate(fromDateTime, isShort) +
            " " +
            getDayFromDate(fromDateTime, isShort) +
            ", " +
            getYearFromDate(fromDateTime, isShort) +
            " " +
            getTimeFromDate(fromDateTime, isShort)
        );
    }
};


export const getDayFromDate = dateTime => {
    const date = new Date(dateTime);
    return (date.getDate() < 10 ? "0" : "") + date.getDate();
};

export const getYearFromDate = dateTime => {
    const dateYear = new Date(dateTime).getFullYear()
    const currentYear = new Date().getFullYear()
    if (dateYear === currentYear) {
        return '';

    }
    return dateYear;
};

export const getTimeFromDate = dateTime => {
    let dd;
    const date = new Date(dateTime);
    dd = "AM";
    var hour = date.getHours();
    if (hour >= 12) {
        hour = date.getHours() - 12;
        dd = "PM";
    }
    if (hour === 0) {
        hour = 12;
    }
    return (
        (hour < 10 ? "0" : "") +
        hour +
        ":" +
        (date.getMinutes() < 10 ? "0" : "") +
        date.getMinutes() +
        " " +
        dd
    );
};

export const isToday = dateTime => {
    const date = new Date(dateTime).getDate();
    return (date === new Date().getDate())
};

export const getMonthFromDate = (dateTime, isShort = false) => {
    const date = new Date(dateTime);
    let monthNames = [
        "JANUARY",
        "FEBRUARY",
        "MARCH",
        "APRIL",
        "MAY",
        "JUNE",
        "JULY",
        "AUGUST",
        "SEPTEMBER",
        "OCTOBER",
        "NOVEMBER",
        "DECEMBER"
    ];
    if (isShort) {
        monthNames = [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC"
        ];
    }
    return monthNames[date.getMonth()];
};



export const getBrandColour = (eventColor = '#F59211', saasColour, isSaas) => {
    if (isSaas && saasColour)
        return convert.hex.rgb(saasColour) ? convert.hex.rgb(saasColour).toString() : convert.hex.rgb(eventColor) ? convert.hex.rgb(eventColor).toString() : convert.hex.rgb('#F59211').toString();
    else
        return convert.hex.rgb(eventColor) ? convert.hex.rgb(eventColor).toString() : convert.hex.rgb('#F59211').toString();
}

import { combineReducers } from 'redux';

import { root } from './rootReducer'
import { event } from './eventReducer'
import { user } from './userReducer'
import { toast } from './toastReducer'
import { meet } from './meetReducer'
import { groupChats } from './groupChatReducer'
import { privateChats } from './privateChatReducer'
import { participants } from './participantsReducer'

const rootReducer = combineReducers({
    root,
    event,
    user,
    toast,
    meet,
    groupChats,
    privateChats,
    participants,
});

export default rootReducer;
export const API = {

  USER_LOGIN: '/rest/v1/yepdesk-live/login', //  /rest/v1/yepdesk-live/login
  EVENT_DETAILS_GET: '/rest/v1/yepdesk-live/event/', //  /rest/v1/yepdesk-live/event/:uri
  MEET_DETAILS_GET: '/rest/v1/yepdesk-live/', //  /rest/v1/yepdesk-live/:roomId

  GROUP_CHATS_GET: 'rest/v1/live/group-messages/', //  /rest/v1/live/group-messages/:roomId/
  PRIVATE_CHATS_GET: 'rest/v1/live/private-messages/', //  /rest/v1/live/private-messages/:userId
  PARTICIPANTS_GET: '/rest/v1/yepdesk-live/' //  /rest/v1/yepdesk-live/:eventId/participants

}


import { ActionType } from '../helpers'

export const showLeftNav = payload => dispatch => {
    dispatch({
        type: ActionType.SHOW_LEFT_NAV,
        payload
    })
}

export const showRightNav = payload => dispatch => {
    dispatch({
        type: ActionType.SHOW_RIGHT_NAV,
        payload
    })
}

export const expandRightNav = payload => dispatch => {
    dispatch({
        type: ActionType.EXPAND_RIGHT_NAV,
        payload
    })
    if (payload === false) {
        dispatch({
            type: ActionType.HIDE_TABS,
            payload,
        })
        dispatch({
            type: ActionType.SHOW_PRIVATE_CHAT,
            payload,
        })
        dispatch({
            type: ActionType.SET_PRIVATE_CHAT_PARTICIPANT,
            payload: {},
        })
    }
}

export const expandLeftNav = payload => dispatch => {
    dispatch({
        type: ActionType.EXPAND_LEFT_NAV,
        payload
    })
}

export const setOffline = payload => dispatch => {
    dispatch({
        type: ActionType.SET_OFFLINE,
        payload
    })
}

export const hideTabs = payload => dispatch => {
    dispatch({
        type: ActionType.HIDE_TABS,
        payload
    })
}

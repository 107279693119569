import React from 'react'
import { Image } from 'react-bootstrap'

import { getUserInitials, getRandomColors } from '../../../helpers';

import './styles.scss'

export const UserAvatar = ({ user = {}, colorIndex = 0, height, className = '' }) => {
    return (
        <div className={`user-avatar-box ${className}`}>
            {user.avatar ? <Image src={user.avatar} style={{ height }} className="user-avatar-image"></Image> :
                <div className="user-avatar" style={{
                    height: height,
                    width: height,
                    background: getRandomColors[colorIndex % getRandomColors.length],
                    borderRadius: height / 2
                }}>
                    {user.name && getUserInitials(user.name)}
                </div>
            }
            {user.isActive &&
                <div className="user-active">
                </div>
            }
        </div>
    )
}
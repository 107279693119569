import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { Spinner, Col, Row } from 'react-bootstrap'
import { isMobileOnly } from 'react-device-detect'

import { setMenuActive, setTabActive, getEventDetails, showRightNav, expandRightNav, setGroupChatReconnecting, sendGroupMessage, sendPrivateMessage, getGroupChats, setChatUnread, joinedGroup, getParticipants, participantsJoined, participantLeft, error, privatePushMessage, setPrivateChatReconnecting } from '../../actions'
import { EventRoutes } from '../../routes'
import { randomString, localStore, logout, socket } from '../../helpers'
import { EventHeader, SideNav } from '../../components'
import { RoundedTabs, ModalComponent } from '../../components/UI'
import { GroupChat, Participants, SwitchProfileModal } from './'

import './styles.scss'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const EventPage = () => {

    const query = useQuery();
    const dispatch = useDispatch();
    const history = useHistory();
    const { eventURI } = useParams();
    const [isPlatformMobile, setIsPlatformMobile] = useState(null);
    const [showSwitchProfileModal, setShowSwitchProfileModal] = useState(false);
    const [userFromUrl, setUserFromUrl] = useState({});
    const [initSocket, setInitSocket] = useState(true);
    const event = useSelector(state => state.event);
    const root = useSelector(state => state.root);
    const user = useSelector(state => state.user);
    const participants = useSelector(state => state.participants);
    const groupChats = useSelector(state => state.groupChats);


    useEffect(() => {
        let userDetailsFromUrl = {
            email: query.get("email") ? query.get("email") : '',
            password: query.get("password") ? query.get("password") : ''
        }
        setUserFromUrl(userDetailsFromUrl);
        if (userDetailsFromUrl.email !== '' || userDetailsFromUrl.password !== '')
            localStore.set('userFromUrl', userDetailsFromUrl);
        query.get("email") && query.delete('email');
        query.get("password") && query.delete('password');
        history.replace({ search: query.toString() });
        setIsPlatformMobile(isMobileOnly);
        !isMobileOnly && dispatch(showRightNav(true));
    }, []);

    useEffect(() => {
        dispatch(getEventDetails({ uri: eventURI }));
    }, [eventURI]);

    useEffect(() => {
        if (!event.loading && user.isLoggedIn && !groupChats.joinedRoom && initSocket) {
            dispatch(getGroupChats({ conversationId: event.conversationId, eventId: event.id, pageno: groupChats.pagination.page, pageSize: groupChats.pagination.pageSize }));
            dispatch(getParticipants({ eventId: event.id, roomId: event.meetId }));
            dispatch(joinedGroup(true));

            socket.emit('join-room', { room_id: event.conversationId, token: user.token, source: event.conversationSource }, (error) => {
                if (error) {
                    dispatch(error({ message: error }));
                }
            });
            socket.on('update-thread', data => {
                let tempMessage = {
                    _id: randomString(15),
                    isSender: user.attendeeId === data.user_id ? true : false,
                    createdTime: data.time,
                    author: data.user_id,
                    body: data.message,
                    attendee: {
                        contact: {
                            email: data.email,
                            displayName: data.name
                        }
                    }
                }
                if (data.type === 'PRIVATE') {
                    dispatch(sendPrivateMessage(tempMessage));
                }
                else {
                    dispatch(sendGroupMessage(tempMessage));
                    dispatch(setChatUnread());
                }
            });

            socket.on('notifications', data => {
                dispatch(privatePushMessage({ message: data.message, name: data.name, userId: data.user_id }));
            });


            socket.on('new-user', data => {
                dispatch(participantsJoined(data));
            });

            socket.on('disconnected', data => {
                dispatch(participantLeft(data));
            });

            socket.on('error', data => {
                if (data) {
                    dispatch(error({ message: data }));
                    if (data === 'Token Invalid') {
                    logoutCurrentuserFunc();
                    }
                }
            });

            socket.on('reconnecting', data => {
                dispatch(setGroupChatReconnecting(true));
                dispatch(setPrivateChatReconnecting(true));

            });

            socket.on('reconnect', data => {
                dispatch(setGroupChatReconnecting(false));
                dispatch(setPrivateChatReconnecting(false));
                socket.emit('join-room', { room_id: event.conversationId, token: user.token, source: event.conversationSource }, (error) => {
                    if (error) {
                        dispatch(error({ message: error }));
                    }
                });
            });

            if ((userFromUrl.password !== '' && user.password !== userFromUrl.password) || (userFromUrl.email !== '' && user.email !== userFromUrl.email))
                setShowSwitchProfileModal(true);
            else
                localStore.remove('userFromUrl');

            setInitSocket(false);
        }
    }, [event, user]);

    useEffect(() => {
        if (!participants.loading) {
            socket.emit('active-users', event.conversationId, (error) => {
                if (error) {
                    alert(error);
                }
            });
        }
    }, [participants.loading]);

    const onSendMessage = (message) => {
        socket.emit('send-message', { room_id: event.conversationId, token: user.token, message, source: event.conversationSource, name: user.name, email: user.email }, (error) => {
            if (error) {
                alert(error);
            }
        });

        let tempMessage = {
            _id: randomString(15),
            isSender: true,
            createdTime: new Date(),
            author: user.attendeeId,
            body: message,
            attendee: {
                contact: {
                    email: user.email,
                    displayName: user.name
                }
            }
        }
        dispatch(sendGroupMessage(tempMessage));
    }

    const menuChanged = item => {
        dispatch(setMenuActive(item.key));
        history.push(`/${eventURI}${item.url}`);
    }

    const secondaryMenuClicked = item => {
        dispatch(setTabActive(item.key));
        dispatch(expandRightNav(true));
    }

    const renderTabContent = () => {
        const activemenu = event.secondaryMenu.filter(menu => menu.isActive);
        switch (activemenu[0].key) {
            case 'chat':
                return <GroupChat SendMessage={onSendMessage} chats={groupChats} event={event} />
            case 'participants':
                return <Participants loading={participants.loading} participants={participants.participants} showPrivateChat={participants.showPrivateChat} privateChatParticipant={participants.privateChatParticipant} showHeader={event.showHeader} />
            case 'polls':
                return 'polls component here'
            default:
                break;
        }
    }

    const renderMobileView = () => {
        return (
            <div className="mobile-view-box text-center">
                <Row className="header-brand-box">
                    <Col>
                        <img src={event.saas.organizationLogo} alt="logo" className='meet-icon' />
                    </Col>
                </Row>
                <Col className="content-box">
                    <div className="py-4">
                        <label className="download-instruction">
                            You need to download {event.isSaas ? event.saas.organizationName : 'Meet'} mobile app to join this meeting on your phone.
                    </label>
                        <span>
                            <label > Room Id: <b>{event.meetId}</b></label> <br />
                            {userFromUrl.password && <label> Password: <b>{userFromUrl.password}</b></label>}
                        </span>
                    </div>
                    <div>
                        <a target="_blank"
                            href={event.meetApp.androidLink} rel="noopener noreferrer"><img className="app-store-icon" alt="playstore logo"
                                src="/assets/icons/google-play-badge.svg" /></a>
                        <a target="_blank" href={event.meetApp.iosLink} rel="noopener noreferrer"><img className="app-store-icon" alt="appstore logo"
                            src="/assets/icons/apple-store-badge.svg" /></a>
                    </div>
                </Col>
            </div>
        )
    }
    const setTabActiveFucntion = (key) => {
        dispatch(setTabActive(key));
    }

    const closeCustomerModal = () => {
        setShowSwitchProfileModal(false);
        localStore.remove('userFromUrl');
    }

    const logoutCurrentuserFunc = () => {
        logout(event.id);
    }

    return (
        <>
            {event.loading ?
                <div className="event-loadder">
                    <Spinner animation="grow" variant="grey" />
                </div>
                :
                <>
                    {event.showHeader && <EventHeader event={event} user={user} height={35} />}

                    {user.isLoggedIn && root.showLeftNav &&
                        <SideNav align="left"
                            onSelectMenu={(e) => menuChanged(e)}
                            expandView={root.expandLeftNav}
                            items={event.mainMenu}
                            activeColor={event.primaryColor} />
                    }
                    {user.isLoggedIn && root.showRightNav &&
                        <SideNav
                            align="right"
                            expandMenu={(e) => dispatch(expandRightNav(e))}
                            expandView={root.expandRightNav}
                            expandType="Tab"
                            activeColor={event.primaryColor}
                            onSelectMenu={(e) => secondaryMenuClicked(e)}
                            allowExpandView
                            items={event.secondaryMenu}
                        >
                            <RoundedTabs menuItems={event.secondaryMenu} activeColor={event.primaryColor} content={renderTabContent} setTabActive={setTabActiveFucntion} hideTabs={root.hideTabs} />
                        </SideNav>}
                    < div className="event-page">
                        {isPlatformMobile ?
                            <>{renderMobileView()}</>
                            :
                            <EventRoutes user={user} />}
                    </div>
                    <ModalComponent
                        show={showSwitchProfileModal}
                        size="md"
                        heading='Switch current user'
                        onClose={closeCustomerModal}
                    >
                        <SwitchProfileModal
                            closeModal={closeCustomerModal}
                            currentUser={user.name}
                            newUser={localStore.get('userFromUrl') ? localStore.get('userFromUrl').email : null}
                            logoutCurrentuser={logoutCurrentuserFunc}
                        />
                    </ModalComponent>
                </>
            }
        </>
    )
}



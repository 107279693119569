
import { API, GET } from '../helpers'

export const getParticipantsFunc = (params, dispatch) => {
    return new Promise((resolve, reject) => {
        const url = API.MEET_DETAILS_GET + params.eventId + '/participants';
        GET(url, { roomId: params.roomId }, {}, {}, true, false, params.eventId
        ).then(({ status, result, message }) => {
            if (status === "success") {
                if (!result.participants) {
                    result.participants = [];
                }


                //Please remove it
                result.participants.map(participant => {
                    return participant.unSeenCount = 0;
                })



                resolve({ status, data: result, message });
            } else if (status === "error") {
                reject({ status: "error", message: message });
            }
        })
            .catch(function (error) {
                reject(error);
            });
    });
};

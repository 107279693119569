import React from 'react'
import { ModalFooter } from '../../components/UI'

/**
* @author
* @function SwitchProfileModal
**/

export const SwitchProfileModal = ({ logoutCurrentuser, loading, closeModal, currentUser, newUser }) => {
    return (
        <>
            <div>
                <div style={{ paddingTop: '.8rem', fontSize: '1rem', lineHeight: 2 }}>
                    Seems like you've already been logged in as <b>{currentUser}</b> for this event.
                     Do you want to log out and login {newUser ? <> as < b > {newUser}</b></> : ''}?
                </div>
            </div>
            <ModalFooter
                isLoading={loading}
                actionLabel='Yes, Logout'
                cancelLabel="No, keep me in"
                actionBtnVariant="danger"
                onActionClick={logoutCurrentuser}
                onClose={closeModal}
            />
        </>
    )

}

import React, { memo, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import { UserCard, UserAvatar } from '../../components/UI'
import { extractNumberFromString } from '../../helpers'
import { hideTabs, showPrivateChatFunc, setPrivateChatParticipant } from '../../actions'
import { PrivateChat } from './privateChat'
import { Close } from '../../assets/icons'
import { user } from '../../reducers/userReducer'

/**
* @author
* @function Participants
* @param {Array} participants - List of participants.
* @param {Boolean} loading - Loading state of participants.
* @param {Boolean} showHeader - Has event header.
**/

export const Participants = memo(({ loading, participants, showHeader, showPrivateChat, privateChatParticipant }) => {

    const dispatch = useDispatch();

    const participantBoxHeight = {
        height: showHeader ? 'calc(100vh - 150px)' : 'calc(100vh - 90px)',
    }

    const viewPrivateChat = (participant) => {
        dispatch(showPrivateChatFunc(true));
        dispatch(setPrivateChatParticipant(participant));
        dispatch(hideTabs(true));
    }

    const viewParticipants = () => {
        dispatch(showPrivateChatFunc(false));
        dispatch(hideTabs(false));
        dispatch(setPrivateChatParticipant({}));
    }

    return (
        <>
            {showPrivateChat ?
                <>
                    <div className="private-chat-box">
                        <div className="back-button" onClick={viewParticipants}>
                            <Close className="close-icon" />
                        </div>
                        <div className="chat-title ellipsis">

                            <UserAvatar className={privateChatParticipant.avatar ? 'd-inline-block' : 'd-inline-flex'}
                                user={privateChatParticipant}
                                colorIndex={extractNumberFromString(privateChatParticipant.id)}
                                height={28}
                            />
                            <span className="pl-2">{privateChatParticipant.name}</span>
                        </div>
                    </div>
                    <PrivateChat toUserId={privateChatParticipant.id} isOrganizer={privateChatParticipant.role === 'ORGANIZER' || privateChatParticipant.role === 'CREATOR' ? true : false} />
                </>
                :
                <div style={participantBoxHeight} className="participant-conatiner">
                    {loading ?
                        <div className="text-center pt-5">
                            < Spinner animation="grow" variant="grey" />
                        </div>
                        :
                        <>{
                            participants.length ?
                                participants.sort((a, b) => (!a.isActive - !b.isActive)).map((participant, index) => {
                                    return (
                                        <div key={`participant${participant.id}${index}`}>
                                            <UserCard
                                                user={participant}
                                                index={extractNumberFromString(participant.id)}
                                                privateMessage={() => viewPrivateChat(participant)}
                                                showMessageIcon={true}
                                            />
                                            {index + 1 < participants.length && <hr className="participant-seperation" />}
                                        </div>
                                    )
                                })
                                :
                                <div className="text-center no-participant-box">
                                    No Participants found
                                </div>
                        }</>
                    }
                </div>
            }
        </>
    )
});

import { API, POST, localStore } from '../helpers'

export const userLoginFunc = (params) => {
    return new Promise((resolve, reject) => {
        POST(API.USER_LOGIN, {}, {}, params, false, false
        ).then(({ status, result, message }) => {
            if (status === "success") {
                let user = {
                    name: result.attendee ? result.attendee.displayName ? result.attendee.displayName : result.attendee.firstName : 'User',
                    email: result.attendee ? result.attendee.email : '',
                    password: result.attendee && result.attendee.password ? result.attendee.password : params.password ? params.password : '',
                    attendeeId: result.attendee.attendeeId ? result.attendee.attendeeId : '',
                    isOrganizer: result.attendee.isOrganizer,
                    token: result.token,
                }
                let allUsers = localStore.get('liveUsers') ? localStore.get('liveUsers') : {};
                allUsers[params.eventId] = user;
                localStore.set('liveUsers', allUsers);
                resolve({ status, data: user, message });
            } else if (status === "error") {
                reject({ status: "error", message: message });
            }
        })
            .catch(function (error) {
                reject(error);
            });
    });
};

import React from 'react'
import { Link } from "react-router-dom"

import { Container, Row, Col, Button } from 'react-bootstrap'

export const Home = () => {
    return (
        <Container className="text-center">
            <Row style={{ paddingTop: '30vh' }}>
                <Col>
                    <h1>YepDesk Virtual events</h1>
                </Col>
            </Row>
            <Row >
                <Col>
                    <h2 style={{ opacity: .5 }}>Coming soon!</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="link"><Link to="/ui-ux-meet/meet">View Event</Link></Button>
                </Col>
            </Row>
        </Container>
    );
}

import React from 'react'

export const Chat = ({ fill, className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width="25" height="25" viewBox="0 0 25 25" fill={fill ? fill : '#bdbdbd'}>
            <g id="chat" transform="translate(-1451.403 -133.621)">
                <rect id="Rectangle_2253" data-name="Rectangle 2253" width="25" height="25" transform="translate(1451.403 133.621)" fill="none" />
                <g id="chat-2" data-name="chat" transform="translate(1449.038 133.621)">
                    <g id="Group_2636" data-name="Group 2636" transform="translate(3.366)">
                        <path id="Path_2044" data-name="Path 2044" d="M15.324,0h-.039A11.893,11.893,0,0,0,5.958,19.3L4.223,23.337a.646.646,0,0,0,.338.851.66.66,0,0,0,.37.045l6.359-1.117a11.459,11.459,0,0,0,3.962.688A11.9,11.9,0,0,0,15.324,0ZM11.245,9.522h3.507a.65.65,0,0,1,0,1.3H11.245a.65.65,0,0,1,0-1.3ZM19.3,14.069H11.245a.65.65,0,0,1,0-1.3H19.3a.65.65,0,0,1,0,1.3Z" transform="translate(-3.366)" />
                    </g>
                </g>
            </g>
        </svg>
    )
}
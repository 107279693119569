import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Container, Row, Col, Button, Spinner, OverlayTrigger, Popover } from 'react-bootstrap'
import ReactMomentCountDown from 'react-moment-countdown'
import { isChrome, isChromium } from 'react-device-detect'


import Moment from 'react-moment'

import { capitalize, getWhenWasTheDate, formatFromToDate, isToday, meetingRoles } from '../../helpers'
import { JitsiMeet } from '../../components/jitsiMeet'
import { showFooter, showHeader, showLeftNav, getMeetDetails, initMeetingAction, joinMeetingAction, joinedMeetingAction, leftMeetingAction, rejoinMeetingAction } from '../../actions'

/**
* @author
* @function EventMeet
**/

export const EventMeet = props => {

    const history = useHistory();
    const dispatch = useDispatch();
    const childRef = useRef();

    const { meetId, eventURI } = useParams();
    const [meetTimeStatus, setMeetTimeStatus] = useState();
    const [browserSupport, setBrowserSupport] = useState(false);


    const event = useSelector(state => state.event);
    const user = useSelector(state => state.user);
    const meet = useSelector(state => state.meet);

    const { initMeeting, joiningMeeting, joinedMeeting, leftMeeting, rejoiningMeeting, role } = meet.user;
    const { title, description, startTime, endTime, isMeetExpired } = meet.details;

    useEffect(() => {
        if (!meetId)
            history.push(`/${eventURI}/meet/${event.meetId}`);
        else {
            dispatch(getMeetDetails({ meetId: event.meetId, eventId: event.id, password: user.password }));
            checkBrowserSupport();
        }
        return () => {
            dispatch(initMeetingAction(true));
        }
    }, [meetId]);

    useEffect(() => {
        if (startTime) {
            setMeetTimeStatus(getWhenWasTheDate(startTime));
        }
    }, [startTime]);

    const joinMeeting = () => {
        dispatch(joinMeetingAction(true));
        childRef.current.joinMeetingFunc();
    }

    const checkBrowserSupport = () => {
        if (isChrome || isChromium) {
            setBrowserSupport(true);
        }
        else
            setBrowserSupport(false);
    }

    const rejoinMeeting = () => {
        if (getWhenWasTheDate(endTime) !== 'PAST') {
            dispatch(rejoinMeetingAction(true));
            childRef.current.joinMeetingFunc();
        }
        else
            window.location.reload();
    }

    const returnToHome = () => {
        history.push(`/${eventURI}/meet`);
    }

    const setJoinedMeeting = () => {
        dispatch(joinedMeetingAction(true));
        dispatch(showFooter(false));
        dispatch(showHeader(false));
        //   dispatch(showLeftNav(false));
    }

    const leaveMeeting = () => {
        dispatch(leftMeetingAction(true));
        dispatch(showFooter(true));
        dispatch(showHeader(true));
        //    dispatch(showLeftNav(true));
    }

    const meetingStarted = () => {
        setMeetTimeStatus(getWhenWasTheDate(startTime));
    }

    const popover = (role) => (
        <Popover id="popover-basic">
            <Popover.Content>
                <h6 className="pt-2">{capitalize(role)} can</h6>
                <ul className="pl-4">
                    {meetingRoles[role].map((property, index) => {
                        return (
                            <li key={index} style={{ listStyleType: 'circle' }}>
                                {property}
                            </li>
                        )
                    })}
                </ul>
            </Popover.Content>
        </Popover >
    );

    const renderMeetingTime = () => {
        return (
            <>
                {meetTimeStatus === 'PAST' ?
                    < div className="meet-timing"> Meeting Ends  {isToday(endTime) ? <Moment fromNow >{endTime}</Moment> : <> on {formatFromToDate(endTime)}</>}
                    </div>
                    :
                    < div className="meet-timing"> Meeting will start on {formatFromToDate(startTime)}
                        {isToday(startTime) && <div className="meet-timer"> <ReactMomentCountDown toDate={new Date(startTime)} onCountdownEnd={meetingStarted} targetFormatMask='HH : mm : ss' /> </div>
                        }
                    </div >
                }
            </>
        )
    }

    const renderMeetingParticipants = () => {
        return (
            <>
                {meetTimeStatus === 'PAST' &&
                    <>  Beatrice, Barbara, Williamson and 5 others in the call. </>
                }
            </>
        )
    }

    const renderMeetLoaderContainer = (message) => {
        return (
            <Container fluid className="meet-status-container">
                <Row>
                    <Col className="py-3">
                        <h3>{message}</h3>
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <div className="event-meet">
            {meet.loading ?
                <div style={{
                    height: '80vh', display: 'flex', alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Spinner animation="grow" variant="grey" />
                </div>
                :
                <>
                    <JitsiMeet ref={childRef}
                        joinMeeting={joiningMeeting}
                        joined={joinedMeeting}
                        leftMeeting={leftMeeting}
                        meetDetails={'obj'}
                        setJoined={setJoinedMeeting}
                        leaveMeeting={leaveMeeting}
                        meet={meet}
                        user={user}
                    />
                    {initMeeting &&
                        <Container className="event-meet-box">
                            <Col>
                                <Row>
                                    <Col sm="6" className="meet-details-box align-self-center">
                                        <h4 className="meet-title"> {title} </h4>
                                        <p className="meet-description">{description}
                                        </p>
                                    </Col>
                                    <Col sm="6">
                                        <div className="meet-action-box">
                                            {browserSupport ? <>
                                                {isMeetExpired ?
                                                    <div>
                                                        <img className="warning-icon" alt="warning" src="/assets/icons/warning.svg" />
                                                        <br />
                                                        <h5> Sorry ! This meet session expired.</h5>
                                                    </div>
                                                    : <div>
                                                        <h4> Ready to join as {capitalize(role)}  ?
                                                            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={popover(role)} >
                                                                <img className="ml-2" src="/assets/icons/question-circle.svg" alt="info" />
                                                            </OverlayTrigger>   </h4>
                                                        {/* <div className="meet-participants">
                                                            {renderMeetingParticipants()}
                                                        </div> */}
                                                        <div style={{ paddingBottom: 15 }}>
                                                            {renderMeetingTime()}
                                                        </div>
                                                        <Button variant="primary" onClick={joinMeeting} size="lg">Join Now</Button>
                                                    </div>
                                                }
                                            </>
                                                : <>
                                                    <div>
                                                        <img className="warning-icon" alt="warning" src="/assets/icons/warning.svg" />
                                                        <br />
                                                        <h5>Browser not supported !</h5>
                                                        <small> Please use a chrome-based browser for joining meetings.</small>
                                                        <div className="browser-icon-box">
                                                            <a href="https://www.google.com/chrome/" target="_blank">
                                                                <img src="/assets/icons/chrome.svg" alt="chrome" className="browser-icon" />Chrome</a>
                                                            <span className="pl-2">or </span><a
                                                                href="https://www.chromium.org/getting-involved/download-chromium" target="_blank"> <img
                                                                    src="/assets/icons/chromium.png" alt="chromium" className="browser-icon" />Chromium</a>
                                                        </div>
                                                    </div> </>}
                                        </div>
                                        <div className="text-right" style={{ padding: '10px 0px' }}>
                                            {/* <small >Need help ?</small> */}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Container>
                    }
                    {
                        joiningMeeting && renderMeetLoaderContainer('Joining meeting.. Please wait')
                    }
                    {
                        rejoiningMeeting && renderMeetLoaderContainer('Rejoining meeting.. Please wait')
                    }
                    {leftMeeting &&
                        <Container fluid className="meet-status-container">
                            <Row>
                                <Col className="py-3">
                                    <h3>You left the meeting</h3>
                                    <div className="py-3">
                                        <Button variant="primary" className="mx-2" onClick={rejoinMeeting} >Rejoin</Button>
                                        <Button variant="light" className="mx-2" onClick={returnToHome} >Return to Home</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    }
                </>}
        </div >
    )

}

import { ActionType } from '../helpers'
import { getEventDetailsFunc } from '../services'
import { error } from '../actions'

export const showHeader = (data) => dispatch => {
    dispatch({
        type: ActionType.SHOW_HEADER,
        payload: data
    })
}

export const showFooter = (data) => dispatch => {
    dispatch({
        type: ActionType.SHOW_FOOTER,
        payload: data
    })
}

export const setMenuActive = (data) => dispatch => {
    dispatch({
        type: ActionType.SET_MENU_ACTIVE,
        payload: data
    })
}

export const setTabActive = data => dispatch => {
    dispatch({
        type: ActionType.SET_TAB_ACTIVE,
        payload: data
    })
}

export const getEventDetails = params => dispatch => {
    getEventDetailsFunc(params, dispatch)
        .then(response => {
            dispatch({
                type: ActionType.EVENT_DETAILS_GET,
                payload: response.data
            })
        })
        .catch(function (e) {
            dispatch(error({ message: e.message ? e.message : 'Error Occured!' }));
        });
}

export const setChatUnread = data => (dispatch, getState) => {
    if (!getState().groupChats.isActive) {
        dispatch({
            type: ActionType.SET_CHAT_UNREAD,
            payload: data
        })
    }
}

export const setChatRead = payload => dispatch => {
    dispatch({
        type: ActionType.SET_CHAT_READ,
        payload
    })
}

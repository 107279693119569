import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap'

import './styles.scss'

/**
* @author
* @function ModalComponent
**/

export const ModalComponent = (props) => {

    const { show, onClose, size, heading, children } = props;
    return (
        <Modal show={show} onHide={onClose} {...props} backdrop="static"
            keyboard={false} size={size} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title>{heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    );
}




export const ModalFooter = ({ isLoading, onClose, onActionClick, actionLabel = 'save', cancelLabel = 'Cancel', actionBtnVariant = 'primary' }) => {
    return (
        <Modal.Footer >
            <Button variant="light" disabled={isLoading} onClick={onClose}> {cancelLabel}
            </Button>
            <Button variant={actionBtnVariant}
                disabled={isLoading} onClick={onActionClick}>  {isLoading ? <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                /> : null} {actionLabel}</Button>
        </Modal.Footer>
    );
}

import React from 'react'

export const Success = ({ fill = '#299b17', className = '' }) => {
    return (
        <svg id="tick" xmlns="http://www.w3.org/2000/svg" width="27.47" className={className} height="27.47" fill={fill} viewBox="0 0 27.47 27.47">
            <g id="Group_2659" data-name="Group 2659">
                <g id="Group_2658" data-name="Group 2658">
                    <path id="Path_2051" data-name="Path 2051" d="M13.735,0A13.735,13.735,0,1,0,27.47,13.735,13.735,13.735,0,0,0,13.735,0Zm0,25.508A11.773,11.773,0,1,1,25.508,13.735,11.773,11.773,0,0,1,13.735,25.508Z" />
                </g>
            </g>
            <g id="Group_2661" data-name="Group 2661" transform="translate(5.874 7.861)">
                <g id="Group_2660" data-name="Group 2660">
                    <path id="Path_2052" data-name="Path 2052" d="M117.587,137.018a.981.981,0,0,0-1.363,0h0l-9.117,9.117-3.231-3.231a.981.981,0,0,0-1.411,1.363l.024.024,3.924,3.924a.981.981,0,0,0,1.387,0l9.811-9.811A.981.981,0,0,0,117.587,137.018Z" transform="translate(-102.19 -136.743)" />
                </g>
            </g>
        </svg>
    )
}